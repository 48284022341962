@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "owl.carousel.min.css";
@font-face {
  font-family: "All Round Gothic Book Oblique";
  src: url("./assets/fonts/AllRoundGothic-BookOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic Bold Oblique";
  src: url("./assets/fonts/AllRoundGothic-BoldOblique.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic Demi";
  src: url("./assets/fonts/AllRoundGothic-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic Book";
  src: url("./assets/fonts/AllRoundGothic-Book.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic Demi Oblique";
  src: url("./assets/fonts/AllRoundGothic-DemiOblique.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic Medium Oblique";
  src: url("./assets/fonts/AllRoundGothic-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic Medium";
  src: url("./assets/fonts/AllRoundGothic-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic XLig Oblique";
  src: url("./assets/fonts/AllRoundGothic-XLigOblique.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic XLig";
  src: url("./assets/fonts/AllRoundGothic-XLig.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "All Round Gothic";
  src: url("./assets/fonts/AllRoundGothic-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Open Sans";
  src: url("./assets/fonts/Open_Sans/OpenSans-VariableFont_wdth,wght.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins 600";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins 400";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "SF Pro Display";
  src: url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf") format("truetype");
}
h1, h2, h3, h4, h5, h6 {
  font-family: "All Round Gothic" !important;
}

/* CSS Document */
/*----------------------------------------------------- */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}

audio:not([controls]) {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a,
a:hover,
a:active,
a:focus {
  outline: none;
  outline-style: none;
  outline-width: 0;
}

img {
  width: auto\9 ;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  border: 0;
  -ms-interpolation-mode: bicubic;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  cursor: pointer;
  -webkit-appearance: button;
}

label,
select,
button,
input[type=button],
input[type=reset],
input[type=submit],
input[type=radio],
input[type=checkbox] {
  cursor: pointer;
}

input[type=search] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

a {
  text-decoration: none;
  box-shadow: none;
  transition: all 0.4s ease-in-out 0s;
}

a:hover {
  text-decoration: none;
  box-shadow: none;
  border-bottom: none;
}

a:focus {
  text-decoration: none;
  box-shadow: none;
  border-bottom: none;
}

h2,
h3,
h5,
h6 {
  font-family: "All Round Gothic";
  font-weight: 400 !important;
}

h1 {
  font-family: "All Round Gothic";
}

h4 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400 !important;
}

body {
  margin: 0;
  padding: 0px !important;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #fff;
  color: #000;
  line-height: normal;
  overflow-x: hidden;
}

.standard-transition, .header_section.mini-scroll.location-disabled::after, .header_section.mini-scroll .menu_sec ul li.active::after, .header_section.mini-scroll .menu_sec ul li a, .header_section.mini-scroll .menu_sec ul li, .header_section.mini-scroll .location-loader, .header_section.mini-scroll .location-form-control, .header_section.mini-scroll .location-icon, .header_section.mini-scroll .logo_sec img, .header_section.mini-scroll .location-disabled-strip, .header_section.mini-scroll, .logo_sec img, .header_section .location-disabled-strip .action-box, .header_section .location-disabled-strip .mesg-box, .header_section .location-disabled-strip, .header_section, .menu_sec ul li.active::after, .menu_sec ul li.active a, .menu_sec ul li.active, .menu_sec ul li a, .menu_sec ul li {
  transition: all 0.3s ease-in;
}

.menu_sec ul li {
  color: #000000;
  font-weight: 400;
  font-family: "Poppins";
}
.menu_sec ul li a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  letter-spacing: 0.05em;
}
.menu_sec ul li::after {
  content: "";
  opacity: 0;
  transition: opacity ease-in-out 0.5s;
  width: 0%;
  height: 0px;
}
.menu_sec ul li.active {
  color: #7bbb43;
  font-weight: 600;
}
.menu_sec ul li.active a {
  font-weight: 600;
  color: #7bbb43;
  letter-spacing: 0.05em;
}
.menu_sec ul li.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  bottom: -25px;
  border-radius: 15px 15px 0px 0px;
  width: 100%;
  height: 5px;
  background-color: #7bbb43;
  opacity: 1;
  margin: 0 auto;
}
.menu_sec ul li:hover:not(.active) a:not(:last-child) {
  color: #315c45;
  transition: color ease-in-out 0.5s;
}
.menu_sec ul li.franchise-web-btn.active::after, .menu_sec ul li.profile-btn.active::after {
  display: none;
}
.menu_sec ul li.franchise-web-btn a {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.12);
}

.menu_sec ul li p {
  background-color: #ffffff;
  color: #003A25;
  padding: 11px 25px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
  display: inline-block;
  font-weight: 400;
  font-family: "Poppins";
}

.menu_sec ul li:nth-last-child(2) {
  margin-top: 0px;
  margin-bottom: 0px;
}

.menu_sec ul li:nth-last-child(2) a {
  background-color: #ffffff;
  color: #003A25;
  padding: 8px 12px;
  font-size: 15px;
  border-radius: 12px;
  display: inline-block;
  border: 1.5px solid #003A25;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  width: 140px;
  text-align: center;
  margin-top: 3px;
}

.menu_sec ul li:last-child a {
  background-color: #003A25;
  color: #ffffff;
  padding: 8px 25px;
  font-size: 15px;
  border-radius: 12px;
  display: inline-block;
  border: 1.5px solid #003A25;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  width: 140px;
  text-align: center;
  margin-top: 3px;
}

.hp_mob_header {
  display: none;
}

.container {
  width: 1300px;
}

.mob_header,
.hp_mob_header,
.mob_gg {
  display: none;
}

.header_section {
  background-color: #fcfcfc;
  padding: 5px 0;
  display: block;
  position: relative;
  height: 72px;
}
@media only screen and (min-width: 320px) {
  .header_section {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
  }
}
.header_section .location-disabled-strip {
  display: flex;
  height: 35px;
  background-color: #fff3cd;
  margin-top: 13px;
}
.header_section .location-disabled-strip .mesg-box {
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  text-align: center;
  flex: 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.header_section .location-disabled-strip .action-box {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 10px;
}

.header_section .row .col-lg-7 {
  float: right;
}

.logo_sec {
  display: block;
  margin: 0;
  padding: 0;
}

.logo_sec img {
  height: 37px;
  width: auto;
  margin-top: 14px;
}

.menu_sec {
  display: block;
  margin-top: 7px;
  padding: 0;
  position: relative;
}

.menu_sec ul {
  margin: 0;
  float: right;
  padding: 0;
  display: -webkit-inline-box;
}

.menu_sec ul li {
  position: relative;
  margin: 11px 16px;
  float: left;
  list-style: none;
}

.menu_sec ul li:last-child {
  margin: 0;
}

.menu_sec ul li {
  font-size: 16px;
  color: #2e2e2e;
  font-weight: normal;
  border-radius: 5px;
  display: inline-block;
  font-family: "Poppins";
  font-style: normal;
}

.menu_sec ul li i {
  color: #7bbb43;
  margin-right: 10px;
}

.menu_sec ul li:nth-child(5) {
  margin-left: 0;
}

.menu_sec ul li:last-child {
  color: #fff;
  font-size: 17px;
  font-weight: 500;
}

.menu_sec ul li:hover {
  color: #74b944;
}

.hp_add {
  float: left;
  display: block;
  margin-top: 18px;
  position: relative;
}

.hp_add p i {
  color: #7bbb43;
  margin-right: 7px;
  font-size: 18px;
}

.hp_add p {
  font-size: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #003a25;
}

.hp_add span {
  position: absolute;
  right: -19px;
  bottom: 1px;
  font-size: 12px;
  color: #003a25;
}

.hp_banner_section {
  position: relative;
  display: block;
  min-height: 700px;
  background-image: url(./assets/images/home-page-try.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-position: 100%;
}

.hp_banner_block {
  margin-top: 185px;
  text-align: center;
}

.service-body {
  margin-top: 90px;
}

.hp_banner_tilte h1 {
  font-family: "All Round Gothic";
  font-size: 45px;
  font-weight: 500;
  color: #ffffff;
  letter-spacing: 1px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
}

.hp_banner_form {
  display: block;
  margin: 20px 0;
}

.app_sec {
  display: block;
  margin: 0;
  padding: 0;
}

.app_sec ul li {
  display: inline-block;
}

.app_sec ul li a {
  width: 35px;
  height: 35px;
  display: block;
  background-color: #74b944;
  margin-left: 7px;
  border-radius: 50px;
}
.app_sec ul li a.hero-section-icon-wrapper {
  border-radius: 0px;
}

.app_sec p a {
  font-size: 17px;
  color: #ffffff;
}

.app_sec p {
  display: block;
  margin-top: 7px;
  clear: both;
}

.md_form {
  width: 60%;
  float: left;
  position: relative;
}

.md_form i.fa.fa-caret-down {
  right: 0;
  color: #245a45;
  font-size: 21px;
  left: auto;
  top: 14px;
}

.md_form i {
  position: absolute;
  left: 0;
  top: 11px;
  color: #7bbb43;
  font-size: 25px;
  z-index: 1;
}

.md_form input {
  padding: 7px 20px;
  position: relative;
  padding-right: 15px;
  width: 100%;
  border: none;
  min-height: 45px;
}

.md_form input::placeholder {
  color: #272626;
  font-size: 15px;
}

.md_locate {
  width: 30%;
  float: left;
  text-align: right;
  padding-right: 25px;
  padding-top: 16px;
}

.md_locate i {
  margin-right: 8px;
  font-size: 16px;
}

.md_locate span {
  font-size: 15px;
  color: #272626;
}

.md_button {
  width: 30%;
  margin: 0 auto;
}

button {
  font-family: Arial;
}

a {
  font-family: Arial;
}

.md_button button {
  background-color: #315c45;
  color: #ffffff;
  font-size: 19px;
  border-radius: 5px;
  width: 100%;
  padding: 15px 0;
  min-height: 45px;
  border: none;
  font-weight: 600;
  box-shadow: 6px 9px 7px rgba(0, 0, 0, 0.15);
}

/*hp_services_section*/
.hp_services_section {
  padding: 5% 0;
  display: block;
  background-color: #ffffff;
}

.sec_title_bar {
  display: block;
  margin: 0;
  padding: 0;
}

.sec_title_bar h2 {
  font-size: 30px;
  color: #003a25;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 22px;
  font-weight: 600;
}

.hp_services_section .item {
  padding: 10px 10px;
}

.tab_bar {
  text-align: center;
  margin: 15px 0 70px;
}
.tab_bar.customer-tabbar {
  margin: 15px 11px 70px;
}

.tab_bar div#nav-tab {
  border: none;
}

.tab_bar a.selected {
  color: #245a45;
  border-bottom: 3px solid #7bbb43;
}

.tab_bar a {
  color: #2e2e2e;
  font-size: 22px;
  font-weight: 600;
  padding: 20px 0;
  margin: 0 25px;
  position: relative;
  display: inline-block;
  border-bottom: 3px solid #ffffff;
}

.btn {
  margin-left: 45px;
  width: 171.36px;
  height: 45.26px;
  top: 6.72px;
  gap: 0px;
  opacity: 0px;
}
.btn .primary {
  font-family: Poppins;
  font-size: 26.88px;
  font-weight: 600;
  line-height: 40.32px;
  text-align: center;
  color: #FFFFFF;
}

.tab_bar a#nav-home-tab:before {
  content: "";
  position: absolute;
  right: -27px;
  width: 1px;
  top: 19px;
  height: 30px;
  background-color: #cac7c7;
}

.serv_block {
  display: block;
  box-shadow: 1px 0px 16px rgba(0, 0, 0, 0.15);
  margin: 0;
  padding: 0;
  overflow: hidden;
  border-radius: 8px;
}
.serv_block:hover {
  box-shadow: 0px 0px 16px 2px rgba(123, 187, 67, 0.63);
}

.img_sec {
  display: block;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
}

.img_sec:before {
  content: "";
  position: absolute;
  width: 85px;
  height: 85px;
  background-color: #77bb45;
  border-radius: 50%;
  bottom: -40px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.img_sec img {
  width: 100%;
  height: auto;
}

.text_sec span {
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  width: 75px;
  align-content: normal;
  height: 75px;
  border-radius: 100px;
  margin: 0 auto;
  background-color: #ffffff;
}

.text_sec {
  text-align: center;
  padding: 50px 15px 25px;
  margin: 0;
  position: relative;
}

.text_sec h4 {
  font-size: 17px;
  color: #003522;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 11px;
  font-family: "All Round Gothic";
}

.text_sec p {
  font-size: 15px;
  color: #2e2e2e;
  line-height: 22px;
  min-height: 45px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.serv_block {
  position: relative;
  margin-bottom: 15px;
}

.div_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #7bbb43;
  overflow: hidden;
  width: 100%;
  height: 100%;
  /*-webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .3s ease;*/
  transition: 0.3s ease;
}

.serv_block:hover .div_overlay {
  /*-webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);*/
  transition: all 0.59s ease;
  height: 100%;
}

.text {
  width: 100%;
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.body-text {
  font-size: 22px;
  font-weight: 400;
  line-height: 27px;
  text-align: justified;
  color: #666666;
}

.text span {
  background-color: #ffffff;
  width: 75px;
  height: 75px;
  border-radius: 100px;
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
  border: 5px solid #c8dd69;
  text-align: center;
}

.text h4 {
  font-size: 17px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 11px;
}

.text p {
  font-size: 15px;
  color: #ffffff;
  line-height: 22px;
  letter-spacing: 1px;
}

.text a {
  font-size: 15px;
  color: #ffffff;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 13px;
  display: inline-block;
}

.text span img {
  opacity: 1;
}

.div_overlay img {
  width: 100%;
  opacity: 0.1;
}

/*hp_main_section*/
.hp_main_section {
  padding: 50px 0;
  background-color: #f4fcee;
  display: block;
  position: relative;
}

.main_title_bar {
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
}

.main_title_bar h2 {
  font-size: 30px;
  color: #003a25;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  line-height: 48px;
}

.main_title_bar p {
  font-size: 17px;
  color: #161616;
  line-height: 30px;
  letter-spacing: 0.5px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.main_title_bar a {
  background-color: #74b944;
  color: #fff;
  padding: 11px 25px;
  font-size: 17px;
  border-radius: 5px;
  display: inline-block;
  font-weight: 500;
  box-shadow: 0px 0px 7px 2px rgba(0, 0, 0, 0.33);
}

.hp_main_section li:nth-child(2) {
  top: -12px;
  left: 15%;
}

.hp_main_section li:nth-child(3) {
  bottom: 50px;
  left: 0;
}

.hp_main_section li:nth-child(4) {
  top: 0;
  right: 60px;
}

.hp_main_section li {
  width: 25px;
  height: 25px;
  position: absolute;
  display: inline-block;
  right: 160px;
  bottom: 100px;
}

/*hp_why_us_section*/
.hp_why_us_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #ffffff;
  position: relative;
}

.hp_why_us_section .container .margin_top {
  display: flex;
}

.list_icon {
  width: 75px;
  height: 75px;
  background-color: #a5cf4d;
  border-radius: 50px;
}

li.why_list_sec {
  list-style: none;
  margin-bottom: 25px;
  clear: both;
  display: inline-block;
  width: 100%;
  position: relative;
}

li.why_list_sec:first-child {
  position: relative;
  top: 0;
  left: 110px;
}

li.why_list_sec:nth-child(2) {
  padding: 0;
  left: 25px;
  top: 0;
}

li.why_list_sec:nth-child(3) {
  left: -25px;
  top: 0;
}

li.why_list_sec:nth-child(4) {
  left: 25px;
}

li.why_list_sec:nth-child(5) {
  left: 110px;
}

.logo_center {
  margin-top: 70px;
}

.right_side_block li.why_list_sec:first-child {
  right: 110px;
  left: auto;
}

.right_side_block li.why_list_sec:nth-child(2) {
  padding: 0;
  right: 25px;
  top: 0;
  left: auto;
}

.right_side_block li.why_list_sec:nth-child(3) {
  right: -25px;
  top: 0;
  left: auto;
}

.right_side_block li.why_list_sec:nth-child(4) {
  right: 25px;
  left: auto;
}

.right_side_block li.why_list_sec:nth-child(5) {
  right: 110px;
  left: auto;
}

.list_text {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 68px;
  text-align: right;
}

.list_text p {
  font-size: 16px;
  text-transform: uppercase;
  color: #161616;
  font-weight: 700;
  letter-spacing: 1px;
  line-height: 18px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.right_side_block .list_text {
  text-align: left;
  justify-content: flex-start;
}

.right_side_block {
  padding-left: 0;
}

.left_side_block {
  padding-right: 0;
}

.right_side_block li:nth-child(1) .list_icon,
.left_side_block li:nth-child(3) .list_icon {
  background-color: #bfd55d;
}

.right_side_block li:nth-child(3) .list_icon,
.left_side_block li:nth-child(2) .list_icon {
  background-color: #78b441;
}

/*hp_how_it_woek_section*/
.hp_how_it_work_section {
  position: relative;
  display: block;
  padding: 5% 0;
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.hp_how_it_work_section:before {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.95);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.center_img {
  text-align: center;
}

.right_block {
  padding: 30px 50px;
  display: block;
}

.left_title_bar {
  padding: 0px;
  display: block;
  margin: 0;
}

.left_title_bar h2 {
  font-size: 30px;
  color: #003a25;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 22px;
  font-weight: 600;
  line-height: 48px;
}

.left_title_bar p {
  font-size: 17px;
  color: #161616;
  letter-spacing: 1px;
  line-height: 30px;
}

.left_title_bar p span {
  color: #7bbb43;
  font-weight: 600;
}

.app_block {
  padding: 0px;
  display: block;
  margin-top: 30px;
}

.app_block h4 {
  font-size: 20px;
  color: #7bbb43;
  text-transform: capitalize;
  letter-spacing: 2px;
  font-weight: 400;
}

.app_block p {
  font-size: 17px;
  color: #161616;
  letter-spacing: 1px;
  line-height: 30px;
  margin: 11px 0;
}

.app_block .form-group {
  margin: 25px 0;
}

.app_block input {
  min-height: 50px;
  border: 1px solid #d2d2d2;
  box-shadow: 5px 0px 9px rgba(0, 0, 0, 0.15);
}

.app_block input::placeholder {
  color: #315c45;
  font-size: 16px;
}

.app_block .form-control:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

.app_block button.app_btn {
  min-height: 50px;
  padding: 0 25px;
  background-color: #74b944;
  color: #fff;
  border: none;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
}

.app_block .app_sec a {
  margin: 5px 5px;
  width: 40px;
  height: 40px;
  display: block;
  background-color: #043a25;
  margin-left: 7px;
  border-radius: 50px;
  float: left;
}

.app_block .app_sec p a {
  color: #033d29;
  font-size: 17px;
  font-weight: 500;
  width: 100%;
  background-color: transparent !important;
}

/*hp_cust_section*/
.hp_cust_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #e0edd6;
  position: relative;
}

.cust_block {
  background-color: #f8f7f7;
  padding: 25px;
  border-radius: 8px;
  position: relative;
  display: block;
  margin-bottom: 30px;
  margin-top: 50px;
}

.cust_block:hover {
  background-color: #ffffff;
  box-shadow: 0px 0px 23px rgba(0, 0, 0, 0.23);
}

.cust_img {
  width: 85px;
  height: 85px;
  margin: 0 auto;
  border-radius: 50px;
  overflow: hidden;
  border: 6px solid #7cbc45;
  position: absolute;
  top: -45px;
  left: 0;
  right: 0;
}

.cust_img:hover {
  border: 6px solid #c6d75f;
}

.cust_img img {
  width: 100%;
  height: auto;
}

.cust_text {
  display: block;
  text-align: center;
  margin-top: 35px;
  padding: 0 20px;
}

.cust_text p {
  font-size: 15px;
  font-style: italic;
  line-height: 30px;
  color: #161616;
}

.cust_text ul {
  margin: 11px 0;
  padding: 0;
}

.cust_text ul li {
  display: inline-block;
  margin: 1px 2px;
  color: #fdb51c;
}

.cust_text h5 {
  font-size: 17px;
  color: #315c45;
  text-transform: uppercase;
}

.cust_text span p {
  font-size: 14px;
  color: #232222;
  font-style: inherit;
}

/*hp_faq_section*/
.hp_faq_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #fbfbfb;
  position: relative;
}

.main_title_bar h6 {
  font-size: 20px;
  color: #2e2e2e;
  line-height: 30px;
  letter-spacing: 1px;
  margin: 20px 0;
  font-weight: 400;
}

/*hp_blog_section*/
.hp_blog_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #ffffff;
  position: relative;
}

.blog_block {
  width: 90%;
  margin: 0 auto;
}

.blog_box {
  display: block;
  box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.blog_box:last-child {
  margin-top: 20px;
}
.blog_box .first-img {
  height: 260px;
  text-align: center;
}
.blog_box .first-img img {
  width: auto;
  margin: 0 auto;
  min-height: 270px;
  max-height: 270px;
}

.blog_img {
  display: block;
  margin: 0;
  padding: 0;
}

.blog_img img {
  width: 100%;
  height: auto;
}

.blog_text {
  padding: 15px 20px;
  display: block;
  margin: 0;
}

.blog_text span {
  font-size: 14px;
  color: #66ac36;
  letter-spacing: 1px;
}

.blog_text h4 {
  font-size: 20px;
  color: #033d29;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 15px 0;
  font-weight: 600;
}

.blog_text p {
  font-size: 16px;
  color: #161616;
  line-height: 22px;
  letter-spacing: 1px;
  height: 7ch;
  white-space: normal;
  overflow: hidden;
  text-align: justify;
}

.blog_text a {
  font-size: 14px;
  color: #66ac36;
  letter-spacing: 1px;
  font-weight: 500;
  text-decoration: underline;
  margin-top: 15px;
  display: block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  text-align: end;
}

.right_side_blog .blog_text h4 {
  font-size: 17px;
  line-height: 30px;
  margin-bottom: 0;
}

.right_side_blog .blog_text p {
  line-height: 25px;
}

.right_side_blog .blog_text {
  padding: 0 15px;
}

.right_side_blog .blog_text span {
  margin-top: 10px;
  display: block;
}

div#blog_box2 {
  margin-bottom: 30px;
}

.right_side_blog .blog_img {
  width: 45%;
  float: left;
  height: 231px;
  overflow: hidden;
}
.right_side_blog .blog_img img {
  height: 100%;
}

.right_side_blog .blog_text {
  padding: 17px 15px;
  width: 55%;
  float: left;
}

.right_side_blog2 .blog_img {
  width: 45%;
  float: left;
  height: 249px;
  overflow: hidden;
}
.right_side_blog2 .blog_img img {
  height: 100%;
}

.right_side_blog2 .blog_text {
  padding: 17px 15px;
  width: 55%;
  float: left;
}

.right_side_blog2 .blog_text p {
  height: 12.3ch;
}

/*hp_location_section*/
.hp_location_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #fbfbfb;
  position: relative;
}

.margin_top {
  margin-top: 0px;
  padding: 30px 50px;
}

.locat_sec {
  width: 20%;
  float: left;
  padding: 0 34px;
}

.locat_sec li {
  list-style: none;
  margin-bottom: 21px;
  padding-left: 20px;
  position: relative;
  font-size: 12px;
  color: #161616;
  cursor: pointer;
}

.locat_sec li:before {
  content: "";
  font: normal normal normal 14px/1 FontAwesome;
  position: absolute;
  left: 0;
  color: #79b64a;
  bottom: 0;
}

.locat_sec a {
  font-size: 17px;
  color: #033d29;
  text-decoration: underline;
}

/*footer_section*/
.footer_section {
  background-color: #033d29;
  padding: 40px 0px 21px;
  display: block;
}

.ft_text {
  margin-top: 15px;
}

.ft_text p {
  color: #f4f8f0;
  font-size: 14px;
  line-height: 23px;
}

.ft_sec {
  width: 20%;
  float: left;
  list-style: none;
}

li#ft_sec {
  width: 28%;
  float: left;
  padding: 0 15px;
}

.ft_link {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
}

.ft_link h4 {
  position: relative;
  margin-bottom: 28px;
  padding-bottom: 18px;
  font-size: 18px;
  text-transform: uppercase;
  color: #ffffff;
  display: block;
  clear: both;
}

.ft_link h4:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 140px;
  height: 1px;
  background-color: #ffffff52;
}

.ft_link h4:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 40px;
  height: 3px;
  background-color: #66ac36;
}

.ft_link li {
  list-style: none;
  margin-bottom: 8px;
}

.ft_link ul {
  padding-left: 0;
}

.ft_link li a {
  color: #f4f8f0;
  font-size: 14px;
}

.ft_link li p {
  color: #f4f8f0;
  font-size: 14px;
  line-height: 23px;
}

li#ft_sec3 {
  width: 15%;
  padding: 0 15px;
}

li#ft_sec4 {
  width: 20%;
  padding: 0 15px;
}

li#ft_sec4 .ft_link li {
  padding-left: 15px;
  position: relative;
}

li#ft_sec4 .ft_link li:nth-child(1):before {
  content: "";
  position: absolute;
  font: normal normal normal 15px/1 FontAwesome;
  left: 0;
  top: 6px;
  color: #79b64a;
}

li#ft_sec4 .ft_link li:nth-child(2):before {
  content: "";
  position: absolute;
  font: normal normal normal 15px/1 FontAwesome;
  left: 0;
  top: 6px;
  color: #79b64a;
}

li#ft_sec4 .ft_link li:nth-child(3):before {
  content: "";
  position: absolute;
  font: normal normal normal 12px/1 FontAwesome;
  left: 0;
  top: 6px;
  color: #79b64a;
}

li#ft_sec5 {
  width: 15%;
  padding-left: 15px;
}

.ft_link ul.social_icon li {
  display: inline-block;
  margin-right: 2px;
}

.ft_link ul.social_icon li a {
  width: 30px;
  height: 30px;
  background-color: #000000;
  display: block;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  padding-top: 6px;
  border: 2px solid transparent;
}

.ft_link ul.social_icon li:nth-child(1) a {
  background-color: #41639d;
}

.ft_link ul.social_icon li:nth-child(1) a:hover {
  background-color: transparent;
  border: 2px solid #41639d;
  color: #ffffff;
}

.ft_link ul.social_icon li:nth-child(2) a:hover {
  background-color: transparent;
  border: 2px solid;
  color: #000000;
}

.ft_link ul.social_icon li:nth-child(3) a {
  background-color: #0678b6;
}

.ft_link ul.social_icon li:nth-child(3) a:hover {
  background-color: transparent;
  border: 2px solid #0678b6;
  color: #ffffff;
}

.ft_link ul.social_icon li:nth-child(4) a {
  background-color: #d53f59;
}

.ft_link ul.social_icon li:nth-child(4) a:hover {
  background-color: transparent;
  border: 2px solid #d53f59;
  color: #ffffff;
}

li#ft_sec5 .app_sec {
  margin-top: 15px;
}

li#ft_sec5 .app_sec li {
  float: left;
  list-style: none;
}

li#ft_sec5 .app_sec li a {
  width: 40px;
  height: 40px;
  display: block;
  background-color: #79b648;
  margin: 10px;
  margin-left: 0;
  border-radius: 50px;
}

.copy_right {
  border-top: 1px solid #ffffff2e;
  margin-top: 90px;
  padding-top: 26px;
}

.copy_right .col-lg-6 {
  padding: 0;
}

.copy_right_text p {
  color: #ffffffff;
  font-size: 15px;
}

.copy_right_text ul {
  float: right;
}

.copy_right_text ul li {
  display: inline-block;
  padding-left: 22px;
  position: relative;
}

.copy_right_text ul li a {
  color: #ffffffff;
  font-size: 15px;
}

.copy_right_text ul li:nth-child(2):before {
  content: "";
  position: absolute;
  left: 10px;
  top: 4px;
  width: 1px;
  height: 15px;
  background-color: #fff;
}

/*hover css*/
.md_button button:hover,
.app_sec ul li a:hover {
  background-color: #003a26;
}

.menu_sec ul li:last-child:hover {
  color: #ffffff;
}

.main_title_bar a:hover,
.app_block button.app_btn:hover {
  background-color: #5dab26;
}

.app_block .app_sec a:hover,
.main_title_bar a:hover,
li#ft_sec5 .app_sec li a:hover {
  background-color: #5dab26;
}

.ft_link li a:hover,
.copy_right_text ul li a:hover {
  text-decoration: underline;
  color: #79b648;
}

.locat_sec a:hover {
  color: #003a26;
}

.blog_text a:hover {
  color: #5dab26;
}

.owl-carousel .owl-nav {
  overflow: hidden;
  height: 0px;
}

.owl-carousel .prev-slide {
  left: -20px;
}

.owl-carousel .nav-btn {
  height: 55px;
  position: absolute;
  width: 30px;
  cursor: pointer;
  top: 45% !important;
}

.page_services_section .owl-carousel .nav-btn {
  top: 30% !important;
}

.owl-carousel .next-slide {
  right: -45px;
}

.hp_cust_section .slick-track {
  top: 50px;
  padding-bottom: 40px;
}

.hp_cust_section .slick-slider {
  margin-bottom: 0;
}

.hp_cust_section button.slick-prev {
  width: 12px;
  height: 21px;
  left: -15px;
  top: 50%;
}

.hp_cust_section button.slick-next {
  width: 12px;
  height: 21px;
  right: -15px;
  top: 50%;
}

/****** home page banner css ebd ******/
/*inner_banner_section inner page banner css*/
.inner_banner_section {
  position: relative;
  min-height: 390px;
  background-position: top;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_banner_block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner_banner_tilte {
  text-align: center;
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: center;
  align-items: center;
}

.inner_banner_tilte h1 {
  font-size: 46px;
  font-family: "All Round Gothic Demi";
  font-weight: 500;
  color: #ffffff;
  text-transform: capitalize;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.6);
}

/*inner_banner_section inner page banner css*/
/*ab_main_section about page css*/
.ab_main_section {
  padding: 2% 0;
  background-color: #ffffff;
  margin: 0;
}

.about_img_sec {
  display: block;
  margin: 0;
  padding: 20px 20px 20px 20px;
  position: relative;
}

.about_img_sec img {
  position: relative;
  border-radius: 5px;
}

.about_img_sec:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #77b541;
  width: 50%;
  height: 100%;
  background-clip: padding-box;
  padding: 85px;
  border-radius: 5px;
  /*clip-path: polygon(0 0, 100% 0, 54% 50%, 0% 100%);*/
}

.about_text_sec {
  display: block;
  margin: 0;
  padding-top: 11px;
}

.about_text_sec p {
  font-size: 17px;
  color: #161616;
  line-height: 30px;
  margin-bottom: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.about_text_sec p b {
  color: #7bbb43;
}

.ab_promise_section {
  padding: 2% 0;
  display: block;
  background-color: #fbfbfb;
}

.promise_block {
  display: block;
  margin: 0;
  padding: 0;
}

.promise_icon {
  width: 90px;
  height: 90px;
  background-color: #7bbb43;
  text-align: center;
  border-radius: 8px;
  border: 8px solid #d1e6be;
  margin: 0 auto;
}

.promise_text {
  text-align: center;
  padding: 25px 0;
  margin: 0;
}

.promise_text h4 {
  color: #053e2b;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 13px;
  letter-spacing: 1px;
  font-weight: 500;
  font-family: "All Round Gothic";
}

.promise_text p {
  color: #161616;
  font-size: 17px;
  line-height: 27px;
}

.ab_promise {
  width: 100%;
  margin: 0 auto;
  clear: both;
  display: block;
  padding: 0 68px;
}

.promise_img {
  text-align: center;
}

.promise_bottom .promise_text {
  text-align: left;
}

.ab_mid_sectopn {
  padding: 2% 0;
  background-color: #f4fcee;
  margin: 0;
  position: relative;
}

.ab_mid_sectopn .promise_text h4 {
  font-family: "All Round Gothic Demi";
  font-size: 24px;
}

.ab_mid_sectopn .promise_text p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 18px;
}

.line_sec {
  width: 81%;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.11);
  clear: both;
  display: block;
  margin: 69px auto;
}

.ab_story_section {
  padding: 2% 0;
  background-color: #ffffff;
  margin: 0;
  display: block;
}

.ab_story_block {
  width: 90%;
  margin: 0 auto;
}

.ab_story_block nav {
  text-align: center;
  display: inline-block;
  border-bottom: 1px solid #315c45;
  clear: both;
  margin-top: 50px;
  width: 95%;
  margin: 50px 27px 0;
}

.ab_story_block nav a.selected {
  color: #9dc844;
  border-bottom: 3px solid #9dc844;
}

.padding_sec {
  padding: 0;
}

.ab_story_block nav a {
  font-size: 17px;
  color: rgba(0, 0, 0, 0.33);
  font-weight: 600;
  padding: 15px;
  display: inline-block;
  margin: 0 11%;
  position: relative;
  border-bottom: 3px solid transparent;
}

.ab_story_block nav a:before {
  content: "";
  position: relative;
  bottom: -33px;
  left: 0px;
  right: 0;
  border: 10px solid transparent;
  border-bottom-color: #9dc844;
  text-align: center;
  margin: 0 auto;
  display: none;
  width: 0;
}

.ab_story_block nav a.selected:before {
  display: block;
}

.ab_story_block div#nav-tab {
  border-bottom: none;
}

.owl-carouse2 .nav-btn {
  height: 55px;
  position: absolute;
  width: 30px;
  cursor: pointer;
  top: 50% !important;
}

.owl-carouse2 .prev-slide {
  left: -33px;
}

.owl-carouse2 .next-slide {
  right: -50px;
}

.story_block {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
}

.story_img {
  margin: 0;
  padding: 0;
}

.story_img img {
  width: 100%;
  height: auto;
}

.story_text {
  padding: 20px 15px;
  display: block;
  margin: 0;
  min-height: 165px;
}

.story_text span {
  color: #73b33a;
  font-size: 17px;
  margin-bottom: 11px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: block;
}

.story_text p {
  color: #161616;
  font-size: 17px;
  line-height: 30px;
}

.ab_about_section {
  padding: 2% 0;
  background-color: #f1f1f1de;
  margin: 0;
  display: block;
}

.ab_about_title {
  padding-right: 50px;
  display: block;
  margin-top: 70px;
}

.ab_about_title h2 {
  font-size: 30px;
  color: #003a25;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 22px;
  font-weight: 600;
  line-height: 48px;
}

.ab_about_title span {
  font-size: 17px;
  color: #161616;
  font-weight: 500;
  line-height: 30px;
  margin: 25px 0;
  display: block;
}

.ab_about_title p {
  font-size: 16px;
  color: #161616;
  line-height: 30px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;
}

.ab_team_section {
  padding: 2% 0;
  background-color: #ffffff;
  margin: 0;
  display: block;
}

.team_block {
  text-align: center;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  position: relative;
  overflow: hidden;
  width: 90%;
  margin: 0 auto;
  height: auto;
}

.team_img {
  display: block;
  margin: 0;
  padding: 15px;
  position: relative;
}

.team_img:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 30px;
  background-color: #ffffffab;
}

.team_img img {
  width: 100%;
  height: auto;
  filter: grayscale(1);
}

.team_text {
  display: block;
  margin-top: 15px;
  padding: 15px;
}

.team_text h4 {
  color: #053e2b;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  font-family: "All Round Gothic Demi";
}

.team_text p {
  color: #161616;
  font-size: 16px;
  margin: 17px 0;
}

.team_text a {
  display: block;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  border: 1px solid #7bbb43;
  color: #7bbb43;
  border-radius: 5px;
  padding-top: 8px;
}

.div_overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #7bbb43;
  overflow: hidden;
  width: 100%;
  height: 0;
  /*-webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);*/
  -webkit-transition: 0.59s ease;
  transition: all 0.59s ease;
}

.team_block:hover .div_overlay {
  /* -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);*/
  height: 100%;
  transition: all 0.59s ease;
}

.text {
  width: 100%;
  padding: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.text span {
  background-color: #ffffff;
  width: 75px;
  height: 75px;
  border-radius: 100px;
  display: block;
  margin: 0 auto;
  margin-bottom: 16px;
  border: 5px solid #c8dd69;
  text-align: center;
}

.text h4 {
  font-size: 20px;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 11px;
}

.team_block .text p {
  font-size: 17px;
  color: #ffffff;
  line-height: 30px;
}

.text h6 {
  color: #ffffff;
  margin-bottom: 11px;
  font-size: 17px;
  letter-spacing: 1px;
}

.team_block .text a {
  background-color: #ffffff;
  color: #6fa93d;
  border-radius: 5px;
  font-size: 18px;
  width: 35px;
  height: 35px;
  padding-top: 7px;
}

.text span img {
  opacity: 1;
}

.div_overlay img {
  width: 100%;
  opacity: 0.1;
}

.team_block .text p i {
  margin-right: 8px;
}

.ab_team_section .owl-item {
  margin-right: 0;
}

/*career page css*/
.career_main_section {
  background-color: #fbfbfb;
  padding: 50px 0;
  margin: 0;
}

.job_block {
  padding: 25px 30px;
  display: block;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
  margin: 20px 0;
}

.job_block h4 {
  font-size: 21px;
  color: #053e2b;
}

.job_block ul {
  margin: 25px 0;
  padding: 0;
  display: inline-block;
}

.job_block ul li {
  list-style: none;
  float: left;
  font-size: 17px;
  color: #161616;
}

.job_block ul li:first-child {
  margin-right: 20px;
  padding-right: 20px;
  border-right: 1px solid #d7d7d7;
}

.job_block ul li i {
  color: #7bbb43;
  margin-right: 7px;
}

.job_block ul li a {
  border: none;
  padding: 0;
  color: #2e2e2e;
  font-weight: normal;
}

.job_block ul li a:hover {
  background-color: transparent;
  border: none;
  text-decoration: underline;
  color: #2e2e2e;
}

.job_block a {
  display: inline-block;
  color: #7cbb44;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #7cbb44;
  padding: 11px 25px;
  border-radius: 5px;
}

.career_main_section .main_title_bar p span {
  color: #94c038;
}

.career_main_section .main_title_bar p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.our_value_section {
  background-color: #ffffff;
  padding: 5% 0;
  margin: 0;
}

.our_value_section .promise_block {
  margin-bottom: 35px;
}

.career_benefit_section {
  background-color: #fbfbfb;
  padding: 2% 0;
  margin: 0;
}

.benefit_list {
  display: block;
  margin-top: 30px;
  padding: 0;
}

.benefit_list li {
  list-style: none;
  width: 50%;
  float: left;
  padding: 15px;
  margin: 15px 0;
}

.benefit_list li h4 {
  color: #053e2b;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 13px;
  font-weight: 500;
}

.benefit_list li p {
  color: #2e2e2e;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 1px;
}

.career_team_section {
  background-color: #ffffff;
  padding: 70px 0 0;
  margin: 0;
}

.career_team_section .slick-slider {
  margin-bottom: 0;
}

.career_team_block {
  display: block;
  margin-bottom: 30px;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 0px 13px rgba(0, 0, 0, 0.13);
}

.career_team_img {
  display: block;
  margin: 0;
  padding: 0;
}

.career_team_img img {
  width: 100%;
  height: auto;
}

.career_team_text {
  display: block;
  margin: 30px 0;
  padding: 0 15px;
  text-align: center;
}

.career_team_text h4 {
  color: #053e2b;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 13px;
  letter-spacing: 1px;
  font-weight: 500;
}

.career_team_text p {
  color: #2e2e2e;
  font-size: 15px;
  line-height: 30px;
  letter-spacing: 1px;
}

.career_join_section {
  background-color: #f4fcee;
  padding: 5% 0;
  margin: 0;
}

.uclean_img_sec {
  display: block;
  margin: 0;
  padding: 25px 25px 0 0px;
  position: relative;
  text-align: right;
}

.uclean_img_sec:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-color: #77b541;
  width: 216px;
  height: 238px;
  background-clip: padding-box;
  padding: 83px;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 50% 49%);
}

.uclean_img_sec img {
  position: relative;
}

.ab_about_title a {
  background-color: #66ac36;
  color: #fff;
  padding: 11px 25px;
  font-size: 17px;
  font-weight: 500;
  display: inline-block;
  border-radius: 5px;
  margin-top: 20px;
}

.join_block {
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
}

.career_icon_sec {
  margin-bottom: 20px;
}

.career_icon_text {
  display: block;
  margin: 0;
  padding: 0;
}

.career_icon_text h4 {
  color: #053e2b;
  font-size: 19px;
  text-transform: uppercase;
  line-height: 30px;
  margin-bottom: 6px;
}

.career_icon_text p {
  font-size: 16px;
  line-height: 25px;
  color: #161616;
  margin-top: 15px;
}

.career_icon_text p span {
  color: #7bbb43;
  font-weight: 600;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.career_team_section button.slick-prev {
  width: 45px;
  height: 45px;
  left: 50px;
  background-color: #ffffff;
  border-radius: 50px;
  background-position: center;
  border: 2px solid #97ca68;
}

.uc_img_item img {
  width: 100%;
  height: auto;
}

.uc_img_item.slick-slide {
  margin: 0 2px;
}

.career_team_section button.slick-next {
  width: 45px;
  height: 45px;
  right: 50px;
  background-color: #ffffff;
  border-radius: 50px;
  background-position: center;
  border: 2px solid #97ca68;
}

.follow_block {
  display: block;
  text-align: center;
}

.follow_block ul {
  margin: 0;
  padding: 0;
}

.hp_main_section.career_join_section li:nth-child(2) {
  top: 50px;
}

.follow_block ul li {
  display: inline-block;
  margin: 0 30px;
  position: relative;
  right: 0 !important;
  top: 0 !important;
  left: 0 !important;
  bottom: 0 !important;
}

.follow_block ul li a {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid #7bbb43;
  border-radius: 50px;
  text-align: center;
  background-color: #ffffff;
  color: #ffffff;
  font-size: 18px;
  overflow: hidden;
  padding-top: 3px;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
}

.follow_block ul li a i {
  background-color: #7bbb43;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  padding-top: 11px;
  margin-top: 1px;
}

/*locate_main_section*/
.locate_page_section {
  padding: 21px 0;
  display: block;
  margin: 0;
  box-shadow: 5px 0px 16px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
}

.media_page li .med_text p a {
  color: #6fb036;
  margin-left: 6px;
  font-size: 17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

.media_page li .med_text p a i {
  margin-right: 6px;
}

.dd_tm ul li {
  padding: 0 !important;
  background-color: transparent;
  box-shadow: none !important;
  margin: 11px 15px 11px 0px;
}

.dd_tm ul li p {
  color: #1e1e1e;
  font-size: 16px;
  font-weight: 600;
  text-transform: lowercase;
}

.dd_tm ul li p i {
  color: #7bbb43;
  font-size: 16px;
  margin-right: 7px;
}

.dd_tm ul li:first-child {
  padding-right: 15px !important;
  border-right: 1px solid #ccc;
}

/*blog_page_section css*/
.blog_page_section {
  padding: 2% 0;
  display: block;
  margin: 0;
}

.left_sidebar {
  background-color: #f8f9fc;
  display: block;
  float: left;
  width: 100%;
  margin-bottom: 15px;
  padding: 20px;
  border-radius: 5px;
}

.left_sidebar h4 {
  color: #003a26;
  font-size: 20px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
}

.search_bar input#search {
  min-height: 52px;
  border-radius: 5px;
  color: #656565;
  font-size: 14px;
}

.search_bar input#search::placeholder {
  color: #656565;
  font-style: italic;
  font-size: 14px;
}

.search_bar span {
  top: 11px !important;
  color: #315c45;
}

.cent_post {
  display: block;
  margin: 0;
  padding: 0;
}

.cent_post li {
  display: inline-block;
  padding: 25px 0;
  border-bottom: 1px solid #cccccc;
}

.cent_post li:last-child {
  border-bottom: transparent;
}

.cent_post li:first-child {
  padding-top: 0;
}

.cent_post li .post_thumb {
  width: 25%;
  float: left;
}

.cent_post li .post_thumb img {
  border: 2px solid #ffffff;
  border-radius: 8px;
  padding: 2px;
  background-color: #ffffff;
}

.post_details {
  padding-left: 15px;
  width: 75%;
  float: left;
}

.post_description {
  display: block;
  margin-bottom: 7px;
  padding: 0;
}

.post_description a {
  color: #383838;
  font-size: 15px;
  line-height: 30px;
  height: 6ch;
  overflow: hidden;
}

.date_time p {
  color: #003a25;
  font-weight: 600;
  font-size: 14px;
}

.date_time i {
  color: #7bbb43;
  margin-right: 8px;
}

.categories_bar {
  display: block;
  margin: 0;
  padding: 0;
}

.categories_bar li {
  display: inline-block;
  padding: 25px 0;
  border-bottom: 1px solid #cccccc;
}

.categories_bar li:first-child {
  padding-top: 0;
}

.categories_bar li a {
  color: #383838;
  font-size: 15px;
  position: relative;
  display: block;
  padding-right: 20px;
  line-height: 30px;
}

.categories_bar li a:before {
  content: "";
  display: block;
  font-family: FontAwesome;
  right: 0;
  font-size: 18px;
  position: absolute;
  top: 5px;
  width: 30px;
  height: 30px;
  background-color: #7bbb43;
  text-align: center;
  color: #ffffff;
  border-radius: 50px;
  padding-top: 1px;
}

.categories_bar li:last-child {
  border-bottom: transparent;
}

.tags_bar {
  display: block;
  margin: 0;
  padding: 0;
}

.tags_bar a {
  display: inline-block;
  background-color: #ffffff;
  box-shadow: 0px 0px 4px #0000002e;
  color: #383838;
  font-size: 14px;
  padding: 11px 21px;
  border-radius: 50px;
  margin: 8px 6px;
}

.arch_bar,
.follow_bar {
  display: block;
  margin: 0;
  padding: 0;
}

.arch_bar li {
  margin-bottom: 18px;
  list-style: none;
}

.arch_bar li a {
  color: #383838;
  font-size: 15px;
}

.follow_bar li {
  display: inline-block;
  margin-right: 7px;
}

.follow_bar li a {
  width: 35px;
  height: 35px;
  display: block;
  background-color: #41639d;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  padding-top: 9px;
}

.follow_bar li:nth-child(2) a {
  background-color: #37b1e2;
}

.follow_bar li:nth-child(3) a {
  background-color: #0678b6;
}

.follow_bar li:last-child a {
  background-color: #d53f59;
}

.news_letter {
  position: relative;
  display: block;
  min-height: 215px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.news_letter {
  padding: 20px;
  border-radius: 8px;
  overflow: hidden;
}

.news_letter:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(124, 187, 68, 0.9);
}

.news_letter h4 {
  color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  position: relative;
  margin-bottom: 20px;
}

.news_letter form {
  position: relative;
}

.news_letter form input {
  min-height: 52px;
  width: 100%;
  border-radius: 5px;
  border: none;
  text-align: center;
}

.news_letter .btn.btn-large {
  background-color: #315c45;
  color: #ffffff;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 400;
  outline: none;
}

.left_sidebar_block {
  padding-right: 45px;
}

.blog_post_block {
  display: block;
  margin-bottom: 30px;
  float: left;
  padding: 1px;
  border-radius: 8px;
  box-shadow: 1px 0px 9px rgba(0, 0, 0, 0.16);
}

.post_image {
  display: block;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
}

.post_content {
  display: block;
  margin-top: 25px;
  padding: 20px;
}

.post_content span {
  color: #66ac36;
  font-size: 15px;
  margin-bottom: 12px;
  display: block;
}

.post_content h4 {
  font-size: 20px;
  color: #003a26;
  line-height: 30px;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.post_content p {
  color: #161616;
  font-size: 16px;
  line-height: 30px;
}

.post_meta {
  display: block;
  margin-top: 15px;
}

.post_meta a {
  background-color: #315c45;
  color: #fff;
  padding: 11px 25px;
  font-size: 17px;
  font-weight: 500;
  border-radius: 5px;
  display: inline-block;
}

.post_meta span.pull-right li {
  display: inline-block;
  margin: 0 2px;
  color: #383838;
}

.post_meta span.pull-right ul.fa_link li a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  padding: 0;
  text-align: center;
  padding-top: 7px;
  color: #41639d;
  border: 1px solid #41639d;
  background-color: transparent;
}

.post_meta span.pull-right ul.fa_link li:last-child a {
  border: 1px solid #d53f59;
  color: #d53f59;
}

.post_meta span.pull-right ul.fa_link li i.fa.fa-share-alt {
  margin-right: 7px;
  color: #7bbb43;
}

.blog_post_block.blog_post_block2 {
  padding: 1px;
}

.blog_post_block2 .post_image {
  border-top-left-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-left-radius: 8px;
}

.blog_post_block.blog_post_block2 .post_content {
  margin-top: 15px;
}

.post_image.post_image2 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.post_list {
  display: block;
  margin: 25px 0;
  padding: 0;
  margin: 20px;
}

.post_list li {
  position: relative;
  list-style: none;
  font-size: 16px;
  color: #161616;
  padding-left: 20px;
  margin: 13px 0;
}

.post_list li:before {
  content: "";
  font: normal normal normal 14px/1 FontAwesome;
  color: #9fca45;
  left: 0;
  position: absolute;
  bottom: 0px;
}

.post_quote {
  display: inline-block;
  padding: 35px 40px 35px 70px;
  background-color: #9fca45;
  border-radius: 8px;
  margin: 20px;
}

.post_quote p {
  font-size: 17px;
  color: #ffffff;
  line-height: 33px;
  position: relative;
  font-style: italic;
  font-family: "All Round Gothic Demi";
}

.post_quote p:before {
  content: "";
  font: normal normal normal 34px/1 FontAwesome;
  position: absolute;
  top: -8px;
  left: -50px;
  color: #ffffff;
}

.post_quote h5 {
  font-size: 17px;
  font-family: "All Round Gothic Demi";
  color: #ffffff;
  line-height: 33px;
  text-align: right;
  margin-top: 20px;
  display: inline-block;
  position: relative;
  float: right;
  padding-left: 60px;
}

.post_quote h5:before {
  content: "";
  position: absolute;
  left: 0;
  top: 18px;
  width: 50px;
  height: 2px;
  background-color: #ffffff;
}

.post_share {
  display: block;
  margin: 15px;
}

.post_share li {
  list-style: none;
  display: inline-block;
  margin-right: 5px;
}

.post_share li:first-child a {
  border: none;
  background: none;
  color: #66867c;
}

.post_share li:first-child a {
  border: none;
  width: auto;
}

.post_share li a {
  width: 35px;
  height: 35px;
  display: block;
  text-align: center;
  border: 1px solid #41639d;
  border-radius: 5px;
  color: #41639d;
  padding-top: 8px;
  padding-left: 5px;
}

.post_share li:last-child a {
  border: 1px solid #d53f59;
  color: #d53f59;
}

.post_share li a i {
  margin-right: 5px;
}

/*covid_page_section css*/
.covid_page_video_section {
  background-color: #ffffff;
  padding: 2% 0;
  display: block;
  margin: 0;
  position: relative;
}

.covid_page_video_section:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fbfbfb;
  height: 55%;
}

.covid_video_block {
  width: 70%;
  margin: 0 auto;
  margin-top: 50px;
  position: relative;
  padding: 15px;
}

.covid_video_block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #7bbb43;
  height: 38%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.covid_video_block img {
  position: relative;
}

.covid_page_video_section .main_title_bar p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.covid_page_video_section .main_title_bar p span {
  color: #7bbb43;
}

.covid_video {
  display: block;
  margin: 0;
  padding: 20px 0px 0 20px;
  position: relative;
}

.covid_video:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #77b541;
  width: 216px;
  height: 238px;
  background-clip: padding-box;
  padding: 83px;
  clip-path: polygon(0 0, 100% 0, 54% 50%, 0% 100%);
}

.covid_video img {
  position: relative;
}

.covid_video_block span {
  position: absolute;
  top: 45%;
  left: 0;
  right: 0;
  text-align: center;
  width: 80px;
  margin: 0 auto;
  height: 80px;
}

.covid_video_text {
  padding: 100px 0;
  display: block;
  margin: 0;
}

.covid_video_text p {
  font-size: 17px;
  color: #161616;
  line-height: 45px;
}

.covid_video_text p span {
  color: #7bbb43;
  font-weight: 600;
}

.covid_page_section {
  padding: 5% 0;
  display: block;
  margin: 0;
}

.covid_block {
  padding: 0;
  display: block;
  margin: 0;
}

.covid_icon {
  width: 115px;
  height: 115px;
  background-color: #7bbb43;
  text-align: center;
  border-radius: 8px;
  border: 8px solid #d1e6be;
  margin: 0 auto;
}

.covid_text {
  text-align: center;
  padding: 25px 0;
  margin: 0;
}

/*.covid_text h4{
	color: #053e2b;
    font-size: 20px;
    text-transform: uppercase;
    margin-bottom: 13px;
    line-height:30px;
    font-weight: 500;
}

.covid_text h4 span {
    font-size: 30px;
    margin-right: 7px;
}*/
.covid_text span {
  margin-right: 10px;
  font-size: 30px;
  color: #003a26;
  font-weight: 600;
}

.covid_clean_section .covid_text h4 {
  color: #003522;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.covid_text p {
  color: #2e2e2e;
  font-size: 17px;
  padding: 0px;
  line-height: 32px;
}

.covid_text p strong {
  font-weight: 600;
  color: #003a26;
}

.covid_mid_section {
  padding: 5% 0;
  background-color: #f4fcee;
  margin: 0;
  position: relative;
}

.eft1 {
  position: absolute;
  top: -16px;
}

.eft2 {
  position: absolute;
  bottom: 90px;
  right: 210px;
}

.covid_clean_section {
  padding: 90px 0;
  background-color: #ffffff;
  margin: 0;
}

.covid_clean_section .covid_text {
  text-align: left;
  padding: 0 70px;
}

.covid_icon_sec {
  text-align: right;
  padding: 0 70px;
}

.covid_block_sec {
  width: 100%;
  float: left;
  position: relative;
}

.covid_block_sec:before {
  content: "";
  width: 1px;
  position: absolute;
  height: 99%;
  background-color: #cccccc;
  top: 0;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: 0;
}

span.point_sec {
  position: absolute;
  left: -18px;
  top: 50%;
  width: 35px;
  height: 35px;
  text-align: center;
  background-color: #7bbb43;
  border-radius: 50px;
  padding-top: 7px;
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
}

.covid_block_2 .covid_icon_sec {
  text-align: left;
}

.covid_block_2 span.point_sec {
  right: -17px;
  left: auto;
}

.covid_block_1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.covid_block_2 {
  margin: 35px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.covid_icon.colr_2,
.promise_icon.colr_2 {
  background-color: #9dc844;
}

.covid_icon.colr_3,
.promise_icon.colr_3 {
  background-color: #b0c823;
}

.ab_about_title a:hover,
.locate_list li a:hover,
.locate_list li a.direct_btn:hover,
.locate_form button.btn.btn-default:hover {
  background-color: #5dab26;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: none;
  border: none;
}

.tags_bar a:hover {
  background-color: #5dab26;
  color: #ffffff;
}

.post_share li a:hover,
.post_meta span.pull-right ul.fa_link li a:hover {
  background-color: #41639d;
  color: #ffffff;
}

.post_share li:last-child a:hover,
.post_meta span.pull-right ul.fa_link li:last-child a:hover {
  background-color: #d53f59;
  color: #ffffff;
}

.follow_bar li a {
  border: 1px solid #41639d;
}

.follow_bar li:nth-child(2) a {
  border: 1px solid #37b1e2;
}

.follow_bar li:last-child a {
  border: 1px solid #d53f59;
}

.follow_bar li:nth-child(2) a:hover {
  color: #37b1e2;
  border: 1px solid #37b1e2;
  background-color: #ffffff;
}

.follow_bar li a:hover {
  color: #41639d;
  border: 1px solid #41639d;
  background-color: #ffffff;
}

.follow_bar li:last-child a:hover {
  color: #d53f59;
  border: 1px solid #d53f59;
  background-color: #ffffff;
}

.news_letter .btn.btn-large:hover {
  background-color: #003a26;
}

.categories_bar li a:before:hover {
  background-color: #5dab26;
}

.post_meta a:hover {
  background-color: #003a26;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
  background-color: #5dab26;
  color: #ffffff;
  border-color: #5dab26;
}

/*franchise_page_section css*/
.franchise_page_section {
  padding: 2% 0;
  display: block;
  margin: 0;
}

.franchise_page_section .ab_about_title {
  margin-top: 0;
  padding: 0;
}

.ab_about_title p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

ul.list_franch {
  margin-top: 12px;
}

ul.list_franch li {
  list-style: none;
  position: relative;
  padding-left: 20px;
  margin: 11px 0;
}

ul.list_franch li:before {
  content: "";
  font: normal normal normal 14px/1 FontAwesome;
  color: #9fca45;
  left: 0;
  position: absolute;
  top: 9px;
}

.franchise_page_section2 {
  padding: 2% 0;
  display: block;
  margin: 0;
  background-color: #fbfbfb;
}

.franchise_page_section2 .ab_about_title {
  margin-top: 80px;
  padding-right: 0;
}

.franchise_page_section2 .about_img_sec:before {
  display: none;
}

.franchise_page_section2 .about_img_sec {
  text-align: center;
}

.franchise_page_section2 .ab_about_title p {
  margin-bottom: 15px;
}

.franchise_form_section {
  padding: 2% 0;
  display: block;
  margin: 0;
}

.franchise_form {
  width: 75%;
  margin: 0 auto;
  margin-top: 10px;
}

.franchise_form input {
  min-height: 52px;
  border: 1px solid #e1e0e0;
  font-size: 14px;
  letter-spacing: 2px;
}

.franchise_form textarea {
  border: 1px solid #e1e0e0;
  font-size: 14px;
  letter-spacing: 2px;
  margin-bottom: 3%;
}

.select_pro_sec.day_select .input-group button.btn.btn-outline-secondary.calendar {
  width: 20%;
  background: #7bbb43;
  color: white;
}

.franchise_form .btn {
  text-align: center;
  width: 22%;
  margin: 0 auto;
  display: block;
  background-color: #7bbb43;
  color: #ffffff;
  border: 1px solid #7bbb43;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 0;
  border-radius: 5px;
}

.franchise_form .btn:hover {
  background-color: #5dab26;
}

/*media_detail_page_section css*/
.media_page_banner {
  background-image: url("./assets/images/Media-new.jpg");
}

.store_page_banner {
  background-image: url("./assets/images/store_page.png");
}

.media_detail_page_section {
  padding: 2% 0;
  display: block;
  margin: 0;
}

.media_block {
  padding: 15px;
  display: block;
  margin: 0;
}

.media_img_block {
  display: block;
  margin: 0;
  padding: 25px 0px 0 25px;
  position: relative;
}

.media_img_block:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #77b541;
  width: 335px;
  height: 370px;
  background-clip: padding-box;
  padding: 83px;
  clip-path: polygon(0 0, 100% 0, 54% 50%, 0% 100%);
}

.media_img_block img {
  position: relative;
}

.media_text_block {
  display: block;
  margin-top: 40px;
  padding-left: 25px;
}

.media_text_block span {
  color: #66ac36;
  font-size: 15px;
  margin-bottom: 20px;
  display: block;
}

.media_text_block h4 {
  font-size: 20px;
  color: #003a26;
  line-height: 30px;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.media_text_block p {
  color: #383838;
  font-size: 16px;
  line-height: 30px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.media_detail_page_section2 {
  padding: 2% 0;
  display: block;
  margin: 0;
  background-color: #fbfbfb;
}

.new_block {
  display: block;
  margin-top: 30px;
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 1px 0px 13px #00000017;
}

.new_post {
  display: block;
  margin: 0;
  padding: 0;
}

.new_post img {
  width: 100%;
  height: auto;
}

.new_post_text {
  display: block;
  padding: 30px 20px;
}

.new_post_text span {
  color: #66ac36;
  font-size: 15px;
  margin-bottom: 12px;
  display: block;
}

.new_post_text h4 {
  font-size: 20px;
  color: #003a26;
  line-height: 35px;
  margin-bottom: 18px;
  text-transform: uppercase;
}

.new_post_text p {
  color: #383838;
  font-size: 15px;
  line-height: 30px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.new_post_text a {
  font-size: 17px;
  color: #003a26;
  margin-top: 12px;
  display: block;
  font-weight: 500;
  letter-spacing: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/*media_page_section css*/
.media_page_section {
  padding: 2% 0;
  display: block;
  margin: 0;
  background-color: #fbfbfb;
}

.med_icon {
  display: block;
  text-align: center;
  box-shadow: 1px 0px 13px #00000014;
  overflow: hidden;
  background-color: #ffffff;
}

.media_page li {
  display: inline-block;
  width: 100%;
  padding: 50px 0;
  border-bottom: 1px solid #c4c4c4;
}

.media_page li:last-child {
  border-bottom: 1px solid transparent;
}

.media_page li .med_text {
  display: block;
  margin: 0;
}

.med_img img {
  box-shadow: 2px 0px 29px rgba(0, 0, 0, 0.09);
  padding: 1px;
  width: 100%;
  height: auto;
}

.media_page li .med_text h4 {
  font-size: 20px;
  color: #003a25;
  margin-bottom: 18px;
  text-transform: uppercase;
  line-height: 35px;
}

.media_page li .med_text p {
  color: #161616;
  font-size: 17px;
  line-height: 35px;
}

.media_page .nav-tabs {
  border-bottom: 1px solid transparent;
}

.media_page nav a.nav-link {
  color: #2e2e2e;
  font-size: 22px;
  font-weight: 600;
  margin-right: 50px;
  padding-bottom: 20px;
  margin-left: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border-bottom: 3px solid transparent;
}

.media_page nav a#nav-home-tab {
  position: relative;
}

.media_page nav a#nav-home-tab:before {
  content: "";
  position: absolute;
  right: -35px;
  width: 1px;
  top: 0;
  height: 30px;
  background-color: #cac7c7;
}

.media_page nav a.nav-link.selected {
  color: #245a45;
  border-bottom: 3px solid #74b944;
}

/*policy_pg_section css*/
.policy_page_banner {
  background-image: url(./assets/images/Privacy_policy.png);
}

.policy_pg_section {
  display: block;
  padding: 5% 0;
  margin: 0;
}

.pg_text_block {
  display: block;
  margin: 0;
  padding: 0;
}

.pg_text_block h2 {
  font-size: 30px;
  color: #003a25;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 22px;
  font-weight: 600;
  line-height: 48px;
}

.pg_text_block h4 {
  font-size: 20px;
  color: #003a26;
  line-height: 30px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.pg_text_block p {
  font-size: 17px;
  color: #161616;
  line-height: 30px;
  margin-bottom: 30px;
}

.pg_text_block p a {
  color: #7bbb43;
}

.pg_add_sec {
  display: block;
  margin: 0;
  padding: 0;
}

.pg_add_sec h4 {
  font-size: 24px;
  color: #003a26;
  line-height: 30px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.pg_add_sec p {
  font-size: 17px;
  color: #161616;
  line-height: 30px;
  margin-bottom: 30px;
}

.pg_add_sec li {
  list-style: none;
  margin: 11px 0;
  position: relative;
}

.pg_add_sec li a {
  font-size: 17px;
  color: #161616;
}

.pg_add_sec li i {
  color: #79b648;
  margin-right: 13px;
  font-size: 18px;
}

/*tearm_pg_section*/
.terms_page_banner {
  background-image: url(./assets/images/terms_and_condition.png);
}

.tearm_pg_section {
  display: block;
  padding: 5% 0;
  margin: 0;
}

.cond_list_sec {
  display: block;
  margin: 0;
  padding: 0;
}

.cond_list_sec ul {
  margin: 0;
  padding: 0;
}

.cond_list_sec ul li {
  font-size: 17px;
  color: #161616;
  line-height: 30px;
  margin-bottom: 15px;
  list-style: decimal;
  margin-left: 30px;
}

.order_sec {
  position: absolute;
  right: 30px;
  z-index: 1;
  top: 20px;
  width: 250px;
  text-align: center;
}

.order_sec ul {
  margin: 0;
  padding: 0;
}

.order_sec ul li {
  display: inline-block;
  padding: 0 11px;
  position: relative;
}

.order_sec ul li:first-child:before {
  content: "";
  position: absolute;
  right: -1px;
  bottom: 0;
  top: 2px;
  width: 2px;
  height: 23px;
  background-color: #d0cece;
}

.order_sec ul li:last-child {
  padding-right: 0;
}

.order_sec ul li:last-child a {
  font-weight: normal;
  color: #ffffff;
  font-size: 16px;
}

.order_sec ul li a i {
  font-size: 18px;
  color: #ffffff;
  width: 30px;
  height: 30px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding-top: 6px;
}

/*build_order_pg_section css*/
.build_order_pg_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #fbfbfb;
}

.build_order_banner {
  background-image: url(./assets/images/build_order_pg_banner.webp);
}

.b_order_item.image {
  background-image: url(./assets/images/all_service_icons_in_one_file.png);
  background-color: #ffffff;
  height: 151px;
  background-size: 369%;
  background-repeat: no-repeat;
}

.b_order_item.Dry.Cleaning {
  background-position: 53% 33%;
}

.b_order_item.image.Premium.Laundry.Kg {
  background-position: 5% 2%;
}

.b_order_item.image.Laundry.\-.Wash.\&.Fold {
  background-position: 4% 32%;
}

.b_order_item.image.Laundry.\-.Wash.\&.Iron {
  background-position: 77% 100%;
}

.b_order_item.image.Steam.Press {
  background-position: 28% 100%;
}

.media_page.build_order div#nav-tab {
  margin-bottom: 70px;
  text-align: center;
}

div#nav-home {
  text-align: -webkit-center;
}

#flex_img {
  flex: 1;
  padding-left: 0;
}

#flex_img img {
  border-radius: 5px;
  background-color: #ffffff;
  text-align: center;
}

#flex_title {
  flex: 2;
}

#flex_text {
  flex: 2;
}

#flex_add {
  flex: 2;
  text-align: right;
}

.quantity.buttons_added {
  border: 1px solid #7f7f7f;
  display: inline-block;
  border-radius: 5px;
}

.quantity input.input-text.qty.text_input {
  width: 38px;
  height: 41px;
  background-color: transparent;
  border: none;
  text-align: center;
  color: #033d29;
  font-weight: 600;
  font-size: 16px;
}

.quantity input.input-text.qty.text_input::placeholder {
  color: #033d29;
}

.quantity input.minus,
.quantity input.plus {
  width: 35px;
  height: 41px;
  background-color: transparent;
  border: none;
  text-align: center;
}

.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

.quantity.buttons_added .minus:focus,
.quantity.buttons_added .plus:focus {
  outline: none;
}

.b_order_list:hover {
  background-color: #f4fcee;
}

.b_order_list:hover .b_order_item h4,
.b_order_list:hover .b_order_item ul li p {
  color: #242e14;
}

.b_order_list:hover .quantity input {
  color: #ffffff;
}

.b_order_list:hover .quantity input.input-text.qty.text_input,
.b_order_list:hover .quantity input.input-text.qty.text_input::placeholder {
  color: #ffffff;
}

.b_order_list:hover .quantity.buttons_added {
  background-color: #9cc840;
  border: 1px solid #9cc840;
}

.b_order_list:hover .b_order_item ul li p img {
  filter: brightness(0.5);
}

.b_order_btn {
  text-align: center;
  width: 100%;
  margin-top: 70px;
  display: block;
}

a.total_pcs_btn {
  background-color: #ffffff;
  color: #414141;
  padding: 11px 25px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #9cc840;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

a.check_out_btn {
  background-color: #9cc840;
  color: #ffffff;
  padding: 11px 25px;
  font-size: 18px;
  border-radius: 5px;
  border: 1px solid #9cc840;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

a.check_out_btn i {
  margin-left: 5px;
  font-size: 14px;
}

/*pricing_pg_section*/
.pricing_pg_section {
  padding: 25px 0 50px;
  display: block;
  margin: 0;
}

.pricing_right_side {
  background-color: #ffffff;
  box-shadow: 2px 0px 29px #0000000d;
  float: left;
}

.tab_pricing_list {
  display: block;
  margin: 0;
  padding: 0;
}

.tab_pricing_list ul {
  margin: 0;
  padding: 0;
}

.tab_pricing_list ul li {
  list-style: none;
  padding: 15px 15px;
}

.tab_pricing_list ul li {
  list-style: none;
  padding: 15px 15px;
  color: #3f3f3f;
  font-weight: 600;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.tab_pricing_list ul.price-list li {
  color: #7bbb43;
  padding-left: 60px;
}

.pricing_teg_line {
  background-color: #005436;
  width: 100%;
  float: left;
  padding: 15px 0;
}

.pricing_teg_line h4 {
  color: #ffffff;
  font-size: 18px;
}

.pricing_page_banner {
  background-image: url(./assets/images/Pricing_new.webp);
}

.franchise_page_bannerv1 {
  background-image: url(./assets/images/franchise/franchise-bg.svg);
  background-size: cover;
  height: 425px;
}
@media (max-width: 767px) {
  .franchise_page_bannerv1 {
    display: none;
  }
}

.pricing_title_bar {
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
}

.pricing_title_bar p {
  color: #161616;
  font-size: 17px;
  line-height: 35px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.pricing_title_bar p span {
  color: #7bbb43;
  font-weight: 600;
}

.search_section {
  margin: 70px auto 10px;
  width: 75%;
}

.select_city,
.select_store {
  width: 37%;
  float: left;
  margin: 0 15px;
  background-color: #ffffff;
  border: 1px solid #7bbb43;
  min-height: 48px;
  border-radius: 18px;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
}

.select_city select,
.select_store select {
  width: 100%;
  border-radius: 18px;
  padding: 18px;
  font-size: 15px;
  text-transform: capitalize;
  color: #2e2e2e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

button.searchButton {
  background-color: #003a25;
  width: 18%;
  min-height: 48px;
  color: #ffffff;
  font-size: 19px;
  text-transform: capitalize;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: none;
  border-radius: 5px;
}

.pr_text {
  text-align: center;
  display: block;
}

.pr_text p {
  color: #383838;
  font-size: 20px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.pr_text p span {
  color: #7bbb43;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.s-hidden {
  visibility: hidden;
  padding-right: 10px;
}

.select {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 100%;
}

.styledSelect {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 15px;
}

.styledSelect:after {
  content: "";
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: black transparent transparent transparent;
  position: absolute;
  top: 25px;
  right: 15px;
}

.styledSelect:active,
.styledSelect.active {
  background-color: #7bbb43;
  color: #ffffff;
}

.styledSelect.active.styledSelect:after {
  border-color: white transparent transparent transparent;
}

.options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  left: 0;
  z-index: 999;
  margin: 0 0;
  padding: 0 0;
  list-style: none;
  border: 1px solid #ccc;
  background-color: white;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  overflow-x: hidden !important;
  overflow: scroll;
  max-height: 375px;
}

.options li {
  padding: 7px 6px;
  margin: 0 0;
  padding: 7px 10px;
  font-size: 15px;
}

.options li:hover {
  background-color: #7bbb43;
  color: white;
}

.select_city .styledSelect:before {
  font: normal normal normal 18px/1 FontAwesome;
  content: "";
  color: #7bbb43;
  margin-right: 7px;
}

.select_store .styledSelect:before {
  content: "";
  background-image: url(./assets/images/store_icon.png);
  width: 19px;
  height: 19px;
  left: 0;
  top: 11px;
  background-repeat: no-repeat;
  padding: 0 13px;
}

.styledSelect.active.styledSelect:before {
  color: #ffffff;
}

.styledSelect.active.styledSelect:before {
  filter: brightness(100);
}

.main_title_bar p span {
  color: #003a25;
  font-weight: 600;
}

.hp_main_section.pricing_main_section .main_title_bar p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/*pricing_main_section*/
.pricing_main_section {
  background-color: #fbfbfb;
  padding: 50px 0 70px;
  margin: 0px;
}

.left_tab_bar {
  background-color: #ffffff;
  box-shadow: 4px 0px 9px rgba(0, 0, 0, 0.07);
  margin: 0;
  padding: 0;
}

.left_tab_bar ul#myTab {
  border: none;
}

.left_tab_bar ul#myTab li {
  width: 100%;
  margin-bottom: 1px;
}

.left_tab_bar ul#myTab li a {
  font-size: 16px;
  color: #807e7e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 15px 20px;
  border: none;
  border-top: 1px solid #e5e5e5;
  border-radius: 0px;
}

.left_tab_bar ul#myTab li.active a {
  border-top: 1px solid #7bbb43;
  color: #ffffff;
  background-color: #7bbb43;
}

.left_tab_bar ul#myTab li a:hover {
  border-top: 1px solid #7bbb43;
  color: #ffffff;
  background-color: #7bbb43;
}

.left_tab_bar ul#myTab h4 {
  font-size: 20px;
  text-transform: uppercase;
  color: #003a25;
  padding: 20px 20px;
}

.top_tab_bar {
  display: inline-block;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #efefef;
}

.navigation_blog ul li a.page-link {
  color: #161616;
}

.top_tab_bar ul {
  margin: 0;
  padding: 0;
}

.navigation_blog ul li a:hover {
  color: #ffffff;
}

.top_tab_bar a img {
  margin-right: 6px;
  margin-left: -16px;
}

.top_tab_bar a.selected img {
  filter: brightness(2.5);
}

.top_tab_bar a {
  padding: 15px 39px;
  display: inline-block;
  font-size: 16px;
  color: #868686;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.top_tab_bar a:hover {
  background-color: #f4fcee;
}

.top_tab_bar a.selected {
  background-color: #7bbb43;
  color: #ffffff;
}

.left_tab_bar ul#myTab li a img {
  margin-right: 7px;
}

.left_tab_bar ul#myTab li a:hover img {
  filter: brightness(22.5);
}

.left_tab_bar ul#myTab li.active a img {
  filter: brightness(22.5);
}

/*store_page_section*/
.store_page_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #fbfbfb;
}

.app_bn {
  margin: 11px 0;
  text-align: center;
  display: block;
}

.app_bn a {
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 22px;
  display: inline-block;
  border: 1px solid #315c45;
  border-radius: 50px;
  background-color: #315c45;
  padding-top: 6px;
}

.app_bn a img {
  padding-left: 2px;
  margin: 0 4px;
}

.bn_btn {
  display: block;
  text-align: center;
  margin: 0;
  padding: 0;
}

.bn_btn a {
  color: #ffffff;
  background-color: #7bbb43;
  display: inline-block;
  margin-top: 11px;
  padding: 15px 30px;
  border-radius: 5px;
  font-size: 19px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  box-shadow: 3px 12px 7px rgba(0, 0, 0, 0.11);
}

.store_map_sec {
  display: block;
  padding: 15px;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.19);
}

.store_detail_sec {
  display: block;
  padding: 20px 60px;
  margin: 0;
}

.store_detail_sec ul {
  margin: 0;
  padding: 0;
}

.store_detail_sec ul.store_detail_info li:before {
  content: "";
  position: absolute;
  left: 0;
  top: 2px;
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  border-radius: 100px;
  border: 1px solid #7bbb43;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.17);
}

.store_detail_sec ul.store_detail_info li:first-child:before {
  background-image: url(./assets/images/loctaion_icon.png);
}

.store_detail_sec ul.store_detail_info li:nth-child(2):before {
  background-image: url(./assets/images/phn_icon.png);
}

.store_detail_sec ul.store_detail_info li:last-child:before {
  background-image: url(./assets/images/time_icon.png);
}

.store_detail_sec ul.store_detail_info li:first-child:before {
  content: "";
  position: absolute;
  left: 0;
  top: 24px;
  background-image: url(./assets/images/loctaion_icon.png);
  width: 44px;
  height: 44px;
  background-color: #ffffff;
  border-radius: 50px;
  border: 1px solid #7bbb43;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.17);
}

.store_detail_sec ul li {
  list-style: none;
  margin-bottom: 20px;
  padding-bottom: 20px;
  padding-left: 60px;
  border-bottom: 1px solid #cccccc;
  position: relative;
}

.store_detail_sec ul li h5 {
  font-size: 16px;
  color: #7bbb43;
  font-weight: 600;
  margin-bottom: 11px;
}

.store_detail_sec ul li a {
  font-size: 16px;
  color: #161616;
  font-weight: 600;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin-bottom: 11px;
  text-decoration: underline;
}

.store_detail_sec ul li p {
  font-size: 16px;
  color: #1e1e1e;
  font-weight: 600;
  line-height: 30px;
  margin-bottom: 11px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.store_services_section {
  padding: 70px 0;
  display: block;
  margin: 0;
}

.store_serv {
  text-align: center;
  display: block;
  margin: 0;
  padding: 0;
}

.store_serv h4 {
  color: #053e2b;
  font-size: 17px;
  margin: 16px 0;
}

.store_serv p {
  color: #161616;
  font-size: 15px;
  line-height: 27px;
}

.store_pg_block_section {
  background-color: #fbfbfb;
  padding: 70px 0;
  display: block;
  margin: 0;
}

.store_pg_text {
  display: block;
  margin: 0;
  padding: 0 20px;
}

.store_pg_text h2 {
  font-size: 30px;
  color: #003a25;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 22px;
  font-weight: 600;
  line-height: 48px;
}

.store_pg_text p {
  font-size: 17px;
  color: #161616;
  line-height: 35px;
}

.store_pg_text p br {
  display: none;
}

.store_pg_text p span {
  color: #65a42d;
  font-weight: 600;
}

.store_pg_img {
  display: block;
  margin: 0;
  padding: 20px 0px 20px 20px;
  position: relative;
}

.store_pg_img:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: #77b541;
  width: 50%;
  height: 100%;
  border-radius: 5px;
  background-clip: padding-box;
  padding: 83px;
  /*clip-path: polygon(0 0, 100% 0, 54% 50%, 0% 100%);*/
}

.store_pg_img img {
  position: relative;
  border-radius: 5px;
}

.store_serv img {
  margin: 0 auto;
}

.store_pg_bottom_section {
  background-color: #ffffff;
  padding: 70px 0;
  display: block;
  margin: 0;
}

ul.work_list {
  float: left;
  margin-right: 35px;
  padding: 0;
  margin-top: 15px;
}

ul.work_list li {
  list-style: none;
  margin: 18px 0;
  color: #161616;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

ul.store_share {
  margin: 0;
  padding: 0;
}

ul.store_share li {
  display: inline-block;
  border: none;
  margin-right: 15px;
  padding-bottom: 0;
  margin-bottom: 11px;
  margin-top: 0px;
  padding-left: 0;
}

ul.store_share li a {
  display: block;
  width: 35px;
  height: 35px;
  border: 1px solid #7bbb43;
  border-radius: 50px;
  text-align: center;
  padding-top: 7px;
  color: #7bbb43;
  background-color: #ffffff;
  box-shadow: 0px 0px 9px rgba(43, 43, 43, 0.35);
}

ul.store_share p {
  color: #505050;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: capitalize;
  font-weight: 600;
}

ul.store_share p i {
  margin-left: 10px;
  color: #7bbb43;
}

ul.app_share {
  margin: 0;
  padding: 0;
}

ul.app_share li {
  display: inline-block;
  border: none;
  margin-right: 5px;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 11px;
}

ul.app_share li a {
  display: block;
  width: 40px;
  height: 40px;
  border: 1px solid #616161;
  border-radius: 50px;
  text-align: center;
  padding-top: 7px;
  font-size: 21px;
  color: #ffffff;
  background-color: #616161;
}

.store_btn {
  display: block;
  margin: 0px;
  padding: 0;
}

.store_btn a.store_price_list {
  font-size: 17px;
  color: #ffffff;
  background-color: #a5cf4d;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 5px;
  margin-right: 13px;
}

.store_btn a.store_order_now {
  font-size: 17px;
  color: #ffffff;
  background-color: #a5cf4d;
  padding: 15px 30px;
  display: inline-block;
  border-radius: 5px;
  margin-right: 13px;
}

ul.app_share li a:hover {
  background-color: #a5cf4d;
  border: 1px solid #a5cf4d;
}

.app_bn a:hover {
  background-color: #003a26;
  border: 1px solid #003a26;
}

.store_btn a.store_price_list:hover {
  background-color: #8ebc2e;
}

.bn_btn a:hover {
  background-color: #5dab26;
}

.store_btn a.store_order_now:hover {
  background-color: #003a26;
}

ul.store_share li a:hover {
  border: 1px solid #7bbb43;
  background-color: #7bbb43;
  color: #ffffff;
}

.store_services_section button.slick-next {
  right: -7px;
  width: 15px;
  height: 25px;
}

.store_services_section button.slick-prev {
  left: -7px;
  width: 15px;
  height: 25px;
}

.form-control:focus {
  border-color: #7bbb43 !important;
  outline: none !important;
  box-shadow: none !important;
}

.job_block a:hover {
  border: 1px solid #7cbb44;
  background-color: #7cbb44;
  color: #ffffff;
}

.follow_block ul li a i:hover {
  background-color: #5dab26;
}

a.linkdin-btn {
  width: 40px;
  height: 40px;
  padding: 10px 12px;
  color: #7bbb43;
  background-color: #ffffff;
  border: 1px solid #7bbb43;
  margin-bottom: 20px;
}

a.linkdin-btn:hover {
  color: #ffffff;
  background-color: #7bbb43;
  border: 1px solid #7bbb43;
}

/*user_profile_section*/
.user_profile_section {
  display: block;
  background-color: #fbfbfb;
  padding: 0;
  margin: 0;
}

.profile_menu_bar {
  background-color: #f4fcee;
  padding: 0% 0px;
  display: block;
  margin: 0;
  float: left;
  width: 100%;
  margin-left: -4%;
  border: 1px solid #e4e3e3;
}

.user_pro {
  float: left;
  width: 115px;
  height: 115px;
  margin: 0 20px;
  border-radius: 100px;
  overflow: hidden;
  border: 1px solid #dcdcdc;
  background-color: #ffffff;
  padding: 4px;
}

.user_pro img {
  border-radius: 50px;
}

.user_name {
  width: 50%;
  float: left;
  display: inline-block;
  margin-top: 30px;
  padding: 15px 15px;
}

.user_name h4 {
  font-size: 20px;
  color: #000000;
  text-transform: uppercase;
}

.profile_menu_bar nav#spy {
  width: 100%;
  float: left;
  margin-top: 25px;
}

.profile_menu_bar nav#spy h4 {
  font-size: 17px;
  color: #2e2e2e;
  padding: 20px;
  display: inline-block;
  width: 100%;
}

.profile_menu_bar nav#spy ul li {
  width: 100%;
  margin-bottom: 1px;
}

.profile_menu_bar nav#spy ul li a {
  font-size: 16px;
  color: #2e2e2e;
  padding: 20px 20px;
  border: none;
  border-bottom: 1px solid #dcdcdc;
  margin: 0;
  border-radius: 0;
}

.profile_menu_bar nav#spy ul li a i {
  margin-right: 11px;
  color: #7bbb43;
}

.profile_menu_bar nav#spy ul li.active a {
  background-color: #7bbb43;
  color: #ffffff;
}

.profile_menu_bar nav#spy ul li a:hover {
  background-color: #7bbb43;
  color: #ffffff;
}

.profile_menu_bar nav#spy ul li.active a i {
  color: #ffffff;
}

.profile_menu_bar nav#spy ul li a:hover i {
  color: #ffffff;
}

.profile_right_block {
  padding: 2% 0;
  display: block;
  margin: 0;
  float: left;
  width: 100%;
}

.profile_form {
  width: 70%;
  margin-left: 50px;
  float: left;
}

.profile_title {
  width: 50%;
  float: left;
  margin: 0;
  padding: 0;
}

.profile_title h2 {
  font-size: 30px;
  color: #003a25;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: 600;
  line-height: 48px;
}

.edit_btn {
  float: right;
  display: block;
  margin-bottom: 7px;
  padding: 0;
}

.edit_btn button {
  font-size: 14px;
  color: #033d29;
  border: 1px solid #939393;
  background-color: #f1f1f1;
  padding: 11px 25px;
  border-radius: 5px;
  display: inline-block;
  font-weight: 600;
}

.profile_form form .form-group {
  margin-bottom: 25px;
}

.profile_form form input {
  min-height: 48px;
  background-color: #ffffff;
  border: 1px solid #dee3da;
  font-size: 16px;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
}

.profile_form form button#signupSubmit {
  background-color: #7bbb43;
  border: 1px solid #7bbb43;
  font-size: 18px;
  min-height: 48px;
  font-weight: 600;
}

.select_pro_sec {
  border-radius: 5px;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
}

.select_pro_sec.mon_select {
  width: 20%;
}

.select_pro_sec.yr_select {
  width: 20%;
  margin-right: 0;
}

.select_pro_sec .styledSelect {
  min-height: 48px;
  color: #555;
}

.select_pro_sec .styledSelect:after {
  border-color: #7bbb43 transparent transparent transparent;
}

.select_pro_sec .styledSelect.active {
  color: #ffffff;
}

.select_pro_sec .options {
  min-height: 48px;
  margin-top: 30px;
  max-height: 183px;
}

.select_pro_sec .options li {
  padding: 11px 15px;
  font-size: 16px;
}

.profile_setting_block .profile_title,
.profile_setting_block .profile_title {
  width: 100%;
}

.user_add_book {
  width: 100%;
  float: left;
  padding: 0;
  margin: 25px;
  display: block;
}

.address_list_sec {
  width: 100%;
  float: left;
  margin-top: 35px;
}

.address_list_sec ul {
  margin: 0;
  padding: 0;
}

.address_list_sec ul li span {
  position: absolute;
  top: 0;
  left: 0;
  width: 46px;
  height: 46px;
  background-color: #ffffff;
  border-radius: 100px;
  border: 2px solid #7bbb43;
  margin: 15px 15px;
}

.address_list_sec ul li {
  list-style: none;
  padding-bottom: 10px;
  padding-top: 5px;
  margin-bottom: 10px;
  border-bottom: 1px solid #e4e3e3;
  padding-left: 70px;
  position: relative;
  border-radius: 30px;
  box-shadow: 5px 2px 8px 0px rgba(0, 0, 0, 0.16);
}

.address_list_sec ul li h4 {
  font-size: 20px;
  color: #003a26;
  padding-top: 15px;
}

.address_list_sec ul li p {
  font-size: 17px;
  line-height: 30px;
  color: #161616;
  margin: 15px 0;
}

.address_list_sec ul li button {
  font-size: 16px;
  color: #81ba5a;
  font-weight: 600;
  margin-right: 50px;
  display: inline-block;
  border: none;
  background-color: transparent;
}
@media (max-width: 360px) {
  .address_list_sec ul li button {
    margin-right: 16px;
  }
}

.new_address_btn {
  clear: both;
  display: block;
  margin: 0;
  padding: 0;
}

.new_address_btn button {
  font-size: 17px;
  color: #7bbb43;
  border: 1px solid #7bbb43;
  min-height: 52px;
  padding: 0 50px;
  background-color: #ffffff;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: 600;
}

.new_address_btn button i {
  margin-right: 10px;
}

.setting_sec {
  width: 100%;
  float: left;
  margin-top: 35px;
}

.profile_setting_block {
  width: 70%;
  margin-left: 50px;
  float: left;
}

.setting_sec li {
  background-color: #ffffff;
  margin-bottom: 20px;
  list-style: none;
  border: 1px solid #e3e3e3;
  padding: 25px 25px;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
}

.setting_sec li h4 {
  font-size: 20px;
  color: #000000;
  margin-bottom: 15px;
}

.setting_sec li p {
  font-size: 17px;
  color: #2a2a2a;
  margin-bottom: 15px;
  line-height: 30px;
}

.setting_sec li h5 {
  font-size: 19px;
  color: #000000;
}

.setting_sec li h5 i {
  margin-right: 10px;
}

.switch_btn {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 18px;
  float: right;
  margin-top: -11px;
}

.switch_btn input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider_check {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #878787;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider_check:before {
  position: absolute;
  content: "";
  height: 23px;
  width: 23px;
  left: -5px;
  bottom: -2px;
  background-color: #b5b4b4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.4);
}

.switch_btn.switch_btn1 {
  margin-top: 0;
}

input:checked + .slider_check {
  background-color: #a5cf4d;
}

input:focus + .slider_check {
  box-shadow: 0 0 1px #f2a4ac;
}

input:checked + .slider_check:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  background-color: #7bbb43;
}

/* Rounded sliders */
.slider_check.round {
  border-radius: 34px;
}

.slider_check.round:before {
  border-radius: 50%;
}

.order_history_block {
  width: 70%;
  margin-left: 50px;
  float: left;
}

.add_new_form {
  width: 100%;
  clear: both;
  display: block;
  background-color: #ffffff;
  padding: 25px;
  box-shadow: 2px 0px 29px #5656563d;
  border-radius: 5px;
  position: relative;
  margin-bottom: 30px;
}

.add_new_form h4 {
  color: #003a25;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.add_new_form .form-group .form-control {
  border: none !important;
  border-bottom: 1px solid #d2cece !important;
  border-radius: 0;
  border-top: none;
  min-height: 50px;
  box-shadow: none;
  color: #414141;
  padding-left: 0;
  font-size: 15px;
}

.add_new_form .has-error .form-control {
  border-color: #a94442 !important;
}

.add_new_form .form-control:focus {
  border-color: #7bbb43 !important;
}

.add_new_form select {
  border: none !important;
  border-bottom: 1px solid #d2cece !important;
  border-radius: 0;
  border-top: none;
  min-height: 50px;
  box-shadow: none;
  color: #414141;
  padding-left: 0;
  font-size: 15px;
  width: 100%;
}

.add_new_form .styledSelect {
  border-bottom: 1px solid #d2cece;
  color: #414141;
  font-size: 15px;
  padding-left: 0;
}

.add_new_form .styledSelect.active {
  background-color: transparent;
}

.add_new_form input#customControlInline {
  min-height: auto;
  margin-right: 7px;
}

.add_new_form button.btn.login_btn {
  width: 100%;
  background-color: #a5cf4d;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  min-height: 50px;
}

.add_new_form label {
  color: #464646;
  font-size: 15px;
  font-weight: normal;
}

.add_new_form input::placeholder {
  color: #414141;
}

.add_new_form.active {
  display: block;
}

.close_form {
  position: absolute;
  top: 20px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  right: 25px;
  display: block;
  border: 1px solid #7aba4d;
  color: #7aba4d;
  text-align: center;
  border-radius: 100px;
  padding: 8px 11px;
  box-shadow: 2px 0px 29px rgba(87, 87, 87, 0.14);
  background-color: #ffffff;
  font-size: 14px;
}

.close_form:hover {
  color: #ffffff;
  background-color: #7aba4d;
}

/*oder history*/
.order_history_block .profile_title {
  width: 100%;
}

.lc_sec {
  width: 100%;
  float: left;
  margin: 30px 0;
  position: relative;
  padding-left: 0;
  display: block;
  padding-top: 30px;
  border-top: 1px solid #cccc;
}

ul.gst_code {
  position: absolute;
  right: 0;
  top: 36px;
}

ul.gst_code li {
  padding: 0 20px;
  color: #454545;
  font-size: 16px;
  display: inline-block;
}

.lc_sec h4 img {
  margin-right: 12px;
  background-color: #ffffff;
  border: 2px solid #7bbb43;
  border-radius: 50px;
}

.lc_sec h4 {
  font-size: 17px;
  color: #7bbb43;
  margin-bottom: 10px;
}

.lc_sec p {
  font-size: 15px;
  color: #111111;
  line-height: 30px;
  padding-left: 56px;
}

.order_delivered_block {
  width: 100%;
  float: left;
}

.order_delivered_list,
.order_item_sec {
  clear: both;
  width: 100%;
  float: left;
  position: relative;
  padding-left: 0;
  display: block;
  margin-bottom: 15px;
}

.order_item_sec h4 {
  padding-left: 55px;
  font-size: 17px;
  color: #7bbb43;
  margin-bottom: 10px;
}

ul.order_item_id li span {
  color: #7bbb43;
  font-weight: 600;
}

.order_delivered_list h4 {
  font-size: 17px;
  color: #7bbb43;
  margin-bottom: 10px;
}

.order_delivered_list h4 img {
  margin-right: 12px;
  background-color: #ffffff;
  border: 2px solid #7bbb43;
  border-radius: 50px;
}

.order_delivered_list ul.order_id,
.order_item_sec ul.order_item_id {
  margin: 0;
  padding-left: 55px;
}

a.paynow_btn {
  background-color: #7bbb43;
  color: #ffffff;
  padding: 11px 25px;
  border-radius: 5px;
  font-size: 17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
}

li.in_p {
  color: #d91515 !important;
  font-size: 17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.order_delivered_list ul.order_id li,
.order_item_sec ul.order_item_id li {
  display: inline-block;
  width: 30%;
  margin: 11px 0;
  color: #454545;
  font-size: 16px;
}

.order_delivered_list ul.order_id li:nth-child(2),
.order_item_sec ul.order_item_id li:nth-child(2) {
  text-align: center;
  width: 12%;
}

.order_delivered_list ul.order_id li:nth-child(3),
.order_item_sec ul.order_item_id li:nth-child(3) {
  width: 55%;
}

ul.order_item_id.invoice_item_sec li b {
  color: #7bbb43;
  font-weight: 600 !important;
}

ul.order_item_id.invoice_item_sec li {
  color: #7bbb43;
  font-weight: 600 !important;
}

ul.order_item_id.invoice_item_sec li i {
  margin-right: 7px;
}

ul.order_item_id.invoice_item_sec li a {
  color: #7bbb43;
  font-size: 17px;
  text-transform: uppercase;
  text-decoration: underline;
}

/*address_page_banner*/
.address_page_banner {
  background-image: url(./assets/images/address_pg_banner.jpg);
}

.page_progress_section {
  padding: 25px 30px !important;
  display: block;
  margin-bottom: 0px;
  background-color: #fffefe;
  box-shadow: 2px 0px 29px rgba(0, 0, 0, 0.14);
}

.prog_step {
  display: block;
  margin: 0;
  padding: 0;
}

.prog_step ul {
  margin: 0;
  padding: 0;
  width: 100%;
  display: inline-flex;
  justify-content: center;
}
@media (max-width: 767px) {
  .prog_step ul {
    justify-content: flex-start;
    margin-left: 22px;
  }
}
@media (max-width: 480px) {
  .prog_step ul {
    margin: 0px;
  }
}
@media (max-width: 378px) {
  .prog_step ul {
    margin-left: -10px;
  }
}

.prog_step ul li:last-child {
  padding-right: 0;
}

.prog_step ul li:last-child:before {
  display: none;
}

.prog_step ul li {
  float: left;
  padding-right: 25%;
  list-style: none;
  text-align: center;
  position: relative;
  cursor: default;
}

.prog_step ul li:before {
  content: "";
  position: absolute;
  top: 17px;
  left: 41px;
  width: 98%;
  height: 1px;
  border: 1px dashed #bfd55d;
}

.prog_step ul li.active p {
  color: #003a25;
}

.prog_step ul li.active i {
  color: #66a52e;
  border: 1px solid #66a52e;
}

.prog_step ul li.active:before {
  border: 1px dashed #66a52e;
}

.prog_step ul li i {
  color: #edf5db;
  border: 1px solid #edf5db;
  width: 35px;
  height: 35px;
  padding-top: 8px;
  border-radius: 100px;
  background-color: #ffffff;
  position: relative;
}

.prog_step ul li p {
  font-size: 17px;
  color: #d2d7d5;
  font-weight: 600;
  line-height: 24px;
  margin-top: 9px;
}

.address_pg_section {
  padding: 2% 0;
  background-color: #ffffff;
  margin: 0;
  display: block;
}

.address_pg_block .profile_title {
  width: 100%;
}

.address_pg_block .profile_title h4 {
  color: #000000;
  font-size: 20px;
  font-family: "All Round Gothic Demi";
  font-weight: 600;
  background-color: #f7f7f7;
  padding: 15px 15px;
  margin: 25px 0;
  border-top: 1px solid #d0d0d0;
}

.address_pg_block .new_address_btn button {
  width: 100%;
  background-color: #7bbb43;
  color: #ffffff;
}

.address_pg_block .new_address_btn button i {
  font-size: 22px;
}

.page_bn_btn {
  text-align: center;
  margin-top: 50px;
  display: block;
  cursor: pointer;
}

.page_bn_btn a.page_back_btn {
  color: #033d29;
  font-size: 17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 15px 74px;
  margin: 0px 0px 0px 0px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px 1px #7bbb43;
  border: 1px solid #ffffff;
}

.page_bn_btn a.page_next_btn {
  color: #ffffff;
  font-size: 17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 10px 10px;
  width: 16%;
  background-color: #7bbb43;
  margin: 0 25px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px #0000001f;
  border: 1px solid #7bbb43;
  -webkit-box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.75);
}

.page_bn_btn a.page_next_btn img {
  margin-left: 0px;
}

.address_pg_block .address_list_sec ul li:last-child {
  border-bottom: none;
}

.profile_form form button#signupSubmit:hover,
.new_address_btn button:hover,
.address_pg_block .new_address_btn button:hover,
.page_bn_btn a.page_next_btn:hover {
  background-color: #5dab26;
  border: 1px solid #5dab26;
  color: #ffffff;
}

.add_new_form button.btn.login_btn:hover {
  background-color: #94c039;
}

.address_list_sec ul li button:hover {
  text-decoration: underline;
}

.edit_btn button:hover,
.page_bn_btn a.page_back_btn:hover {
  background-color: #b5cc51;
  border: 1px solid #b5cc51;
  color: #ffffff;
}

/*date_time_pg_section*/
.date_time_page_banner {
  background-image: url(./assets/images/date_time_pg_banner.jpg);
}

.order_summary_page_banner {
  background-image: url(./assets/images/order_summary.jpg);
}

.date_time_pg_section {
  padding: 5% 0;
  display: block;
  margin: 0;
  background-color: #ffffff;
}

.date_time_block {
  width: 50%;
  margin: 0 auto;
  margin-top: 50px;
  text-align: center;
}

/**
 * Zabuto Calendar
 */
div.zabuto_calendar {
  margin: 0;
  padding: 0;
}

div.zabuto_calendar .table {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, 0.12);
  border-top-left-radius: 5px;
  overflow: hidden;
  border-top-right-radius: 5px;
}

div.zabuto_calendar .table th,
div.zabuto_calendar .table td {
  padding: 4px 2px;
  text-align: center;
}

div.zabuto_calendar .table tr th,
div.zabuto_calendar .table tr td {
  background-color: #ffffff;
}

tr.calendar-month-header {
  background-color: #80ba4a;
}

div.zabuto_calendar .table tr.calendar-month-header th {
  background-color: transparent;
}

div.zabuto_calendar .table tr.calendar-month-header th span {
  cursor: pointer;
  display: inline-block;
  padding-bottom: 10px;
  color: #ffffff;
  text-shadow: none;
  font-size: 20px;
  text-transform: uppercase;
}

div.zabuto_calendar .table tr.calendar-dow-header th {
  background-color: #f3f3f3;
  padding: 17px 0;
  text-transform: uppercase;
  color: #315d46;
  font-size: 15px;
}

div.zabuto_calendar .table tr:last-child {
  padding-bottom: 15px;
}

div.zabuto_calendar .table tr.calendar-month-header th {
  padding-top: 12px;
  padding-bottom: 4px;
}

div.zabuto_calendar .table-bordered tr.calendar-month-header th {
  border-left: 0;
  border-right: 0;
}

div.zabuto_calendar .table-bordered tr.calendar-month-header th:first-child {
  border-left: 1px solid #dddddd;
}

div.zabuto_calendar div.calendar-month-navigation {
  cursor: pointer;
  margin: 0;
  padding: 0;
  padding-top: 5px;
}

div.zabuto_calendar tr.calendar-dow-header th,
div.zabuto_calendar tr.calendar-dow td {
  width: 14%;
}

div.zabuto_calendar .table tr td div.day {
  margin: 0;
  padding-top: 15px;
  padding-bottom: 20px;
  color: #315d46;
  font-size: 17px;
}

/* actions and events */
div.zabuto_calendar .table tr td.event div.day,
div.zabuto_calendar ul.legend li.event {
  background-color: #fff0c3;
}

div.zabuto_calendar .table tr td.dow-clickable,
div.zabuto_calendar .table tr td.event-clickable {
  cursor: pointer;
}

/* badge */
div.zabuto_calendar .badge-today,
div.zabuto_calendar div.legend span.badge-today {
  background-color: #357ebd;
  color: #ffffff;
  text-shadow: none;
}

div.zabuto_calendar .badge-event,
div.zabuto_calendar div.legend span.badge-event {
  background-color: #ff9b08;
  color: #ffffff;
  text-shadow: none;
}

div.zabuto_calendar .badge-event {
  font-size: 0.95em;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
}

/* legend */
div.zabuto_calendar div.legend {
  margin-top: 5px;
  text-align: right;
  display: none;
}

div.zabuto_calendar div.legend span {
  color: #999999;
  font-size: 10px;
  font-weight: normal;
}

div.zabuto_calendar div.legend span.legend-text:after,
div.zabuto_calendar div.legend span.legend-block:after,
div.zabuto_calendar div.legend span.legend-list:after,
div.zabuto_calendar div.legend span.legend-spacer:after {
  content: " ";
}

div.zabuto_calendar div.legend span.legend-spacer {
  padding-left: 25px;
}

div.zabuto_calendar ul.legend > span {
  padding-left: 2px;
}

div.zabuto_calendar ul.legend {
  display: inline-block;
  list-style: none outside none;
  margin: 0;
  padding: 0;
}

div.zabuto_calendar ul.legend li {
  display: inline-block;
  height: 11px;
  width: 11px;
  margin-left: 5px;
}

div.zabuto_calendar ul.legend div.zabuto_calendar ul.legend li:first-child {
  margin-left: 7px;
}

div.zabuto_calendar ul.legend li:last-child {
  margin-right: 5px;
}

div.zabuto_calendar div.legend span.badge {
  font-size: 0.9em;
  border-radius: 5px 5px 5px 5px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 2px;
  padding-bottom: 3px;
}

/*div.zabuto_calendar .table tr td div.day:hover {
    background-color: #b5cc51;
    color: #ffffff;
    border-radius: 5px;
}*/
/* responsive */
@media (max-width: 979px) {
  div.zabuto_calendar .table th,
div.zabuto_calendar .table td {
    padding: 2px 1px;
  }

  .profile_form form input {
    box-shadow: 2px 0px 29px rgba(87, 87, 87, 0);
  }

  .profile_right_block {
    border: 1px solid #e4e3e3;
  }

  .add_new_form {
    width: auto;
  }

  .b_order_item.image {
    background-color: #ffffff;
    height: 105px;
    background-size: 466%;
    background-repeat: no-repeat;
  }

  .b_order_item.Dry.Cleaning {
    background-position: 53% 33%;
  }

  .b_order_item.image.Premium.Laundry.Kg {
    background-position: 7% 2%;
  }

  .b_order_item.image.Laundry.\-.Wash.\&.Fold {
    background-position: 7% 32%;
  }

  .b_order_item.image.Laundry.\-.Wash.\&.Iron {
    background-position: 75% 100%;
  }

  .b_order_item.image.Steam.Press {
    background-position: 28% 100%;
  }
}
/**
 * Zabuto Calendar
 */
.slot_block {
  margin-top: 50px;
  background-color: #ffffff;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, 0.12);
  padding: 0;
  border-top-left-radius: 5px;
  overflow: hidden;
  border-top-right-radius: 5px;
}

.slot_block .slot_title h4 {
  text-align: center;
  text-transform: uppercase;
  font-size: 20px;
  background-color: #80ba4a;
  color: #ffffff;
  padding: 20px 0;
}

.slot_select {
  display: block;
  margin: 0;
  padding: 0;
  background-color: #f3f3f3;
  float: left;
  width: 100%;
}

.slot_select ul {
  margin: 0;
  padding: 0;
}

.slot_select ul li {
  float: left;
  list-style: none;
  text-align: center;
  width: 33%;
  font-size: 18px;
  text-transform: uppercase;
  color: #003a25;
  padding: 13px;
}

.slot_select ul li:hover {
  background-color: #ffffff;
}

.slot_time {
  padding: 20px;
  display: block;
  width: 100%;
  float: left;
}

.slot_time ul {
  width: 33%;
  float: left;
  text-align: center;
  padding-left: 0 !important;
}

.slot_time ul li {
  list-style: none;
  margin: 15px 15px;
  padding: 15px 15px;
  border-radius: 5px;
  border: 1px solid #d3d3d2;
  font-size: 14px;
  color: #1d1d1d;
  width: max-content;
  display: inline-block;
  box-shadow: none;
  cursor: pointer;
  background-color: #ffffff;
  display: inline-block;
}

.slot_time ul li:hover {
  background-color: #7bbb43;
  color: #ffffff;
  border: 1px solid #7bbb43;
}

/*login popup css strat*/
.loginmodal-container {
  padding: 30px;
  max-width: 420px;
  width: 100% !important;
  background-color: #ffffff;
  margin: 0 auto;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.modal-backdrop.in {
  opacity: 0 !important;
}

.modal-backdrop {
  position: relative;
}

.modal-open {
  overflow: scroll;
}

.login_form_info p {
  text-align: center;
  color: #919191;
  font-size: 15px;
}

.login_form_info p strong {
  color: #4f4f4f;
}

.login-help {
  font-size: 12px;
}

.form_title_bar {
  text-align: center;
  margin: 0;
  padding: 0;
}

.form_title_bar img {
  margin-bottom: 25px;
}

.form_title_bar h1 {
  font-size: 40px;
  color: #315c45;
  font-family: "All Round Gothic Demi";
  margin-bottom: 25px;
}

.login_form_info {
  margin-top: 30px;
  display: block;
  float: left;
  width: 100%;
}

.login_form_info label {
  font-size: 15px;
  color: #6e6e6e;
  font-weight: normal;
  margin-bottom: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: block;
}

.login_form_info input {
  font-size: 14px;
  color: #7f7f7f;
  background-color: #f9f9f9;
  border: 1px solid #e4e3e3;
  min-height: 48px;
  padding: 0 15px;
  width: 100%;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  box-shadow: none;
}

.login_form_info input::placeholder {
  color: rgba(0, 0, 0, 0.3);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
}

.login-help {
  text-align: right;
  margin: 20px 0;
}

.login-help a {
  font-size: 15px;
  color: #6e6e6e;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.login_form_info input.login {
  width: 100%;
  background-color: #65BA0D;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid #65BA0D;
  color: #ffffff;
  font-family: "Poppins";
  font-size: 16px;
  border-radius: 5px;
  transition: all ease 1s;
}
.login_form_info input.login:disabled {
  background-color: #F8F8F8;
  color: rgba(0, 0, 0, 0.4);
  border: 1px solid rgba(0, 0, 0, 0.11);
  cursor: not-allowed;
  transition: all ease 1s;
  box-shadow: none;
}

.social_buttons {
  margin: 20px 0;
  display: block;
  text-align: center;
  width: 100%;
  align-items: center;
  float: left;
  display: flex;
}

.social_buttons a {
  background-color: #f3f4f6;
  width: 45%;
  padding: 10px 0;
  font-size: 15px;
  border-radius: 10px;
  color: #5f5f5f;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: inline-block;
}

.sign_up_ac {
  text-align: center;
  display: block;
}

.sign_up_ac p {
  font-size: 15px;
  color: #464646;
  margin-bottom: 11px;
}

.sign_up_ac a {
  font-size: 15px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #75b945;
}

.form_bottom {
  text-align: center;
  display: block;
  padding-top: 20px;
  margin-top: 20px;
  border-top: 1px solid #cccccc;
  clear: both;
  margin-bottom: 10px;
}

.form_bottom p {
  font-size: 14px;
  color: #464646;
  margin-bottom: 11px;
}

.form_bottom a {
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #75b945;
}

.login-help a:hover,
.sign_up_ac a:hover,
.form_bottom a:hover,
.otp_code a:hover {
  text-decoration: underline;
}

.login_form_info .login:hover {
  background-color: #6ca935;
  border: 1px solid #6ca935;
}

input#loginpassword {
  position: relative;
}

span.right_icon {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  right: 113px;
  border-radius: 100px;
  padding: 4px;
  overflow: hidden;
  border: 1px solid #75b945;
  margin-top: -43px;
  cursor: pointer;
}

span.right_icon img {
  background-color: #75b945;
  border-radius: 100px;
  width: 30px;
  height: 30px;
}

.modal {
  background-color: rgba(0, 0, 0, 0.85);
}

.forget_password_form,
.sign_up_form,
.otp_form {
  display: block;
}

.otp_code {
  display: block;
  text-align: center;
  margin: 30px 0;
  clear: both;
}

.form_title_bar p {
  font-size: 14px;
  color: #75b945;
}

.otp_code a {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #75b945;
}

.otp_code p {
  font-size: 15px;
  line-height: 30px;
  color: #857676;
}

#otp input[type=number]::-webkit-inner-spin-button,
#otp input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.inputs input {
  width: 40px;
  height: 40px;
  display: inline-block;
  margin: 0 7px;
  border: 1px solid #75b945;
  background-color: #ffffff;
  box-shadow: 1px 0px 8px rgba(0, 0, 0, 0.14);
}

.login-help span {
  display: block;
  width: 40px;
  height: 40px;
  margin: 15px auto;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #dedcdc;
  border-radius: 50px;
  font-size: 17px;
  color: #000000;
  padding-top: 9px;
  box-shadow: 1px 0px 8px #00000029;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.inputs input#fourth,
.inputs input#fifth,
.inputs input#sixth {
  background-color: #e3f2da;
  border: 1px solid #e3f2da;
}

/*login popup css end*/
/*order_summary_pg_section*/
.order_summary_pg_section {
  padding: 2% 0;
  display: block;
  margin: 0;
}

.sec_title_bar p {
  text-align: center;
  color: #2e2e2e;
  font-size: 15px;
  line-height: 30px;
}

.order_summary_pg {
  padding: 25px;
  background-color: #ffffff;
  display: block;
  width: 100%;
  float: left;
  box-shadow: 1px 0px 17px #00000030;
}

.order_id {
  display: block;
  margin: 0;
  padding: 0;
}

.order_id h4 {
  color: #7bbb43;
  font-size: 17px;
  margin-bottom: 15px;
  display: inline-block;
  margin-right: 15px;
}

.order_id p {
  color: #111111;
  line-height: 30px;
  font-size: 16px;
}

.order_id ul.gst_code {
  position: relative;
  display: inline-block;
  top: 0;
}

.order_info {
  width: 100%;
  float: left;
  display: block;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #cccccc;
}

.order_info ul {
  margin: 0;
  padding: 0;
}

.order_info ul li {
  list-style: none;
  width: 40%;
  margin: 0;
}

.order_info ul li span {
  font-size: 16px;
  color: #70b336;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.order_info ul li:nth-child(2) {
  text-align: center;
  width: 100%;
}

.order_info ul li:first-child {
  font-size: 16px;
  color: #454545;
  width: 35%;
}

.order_info ul li:last-child {
  font-size: 16px;
  color: #454545;
}

ul.order_no.invoice_item_sec li:last-child {
  color: #7bbb43;
  font-weight: 600;
}

ul.order_no.invoice_item_sec li:last-child i {
  margin-right: 7px;
}

ul.order_no.invoice_item_sec li:last-child a {
  color: #7bbb43;
  font-weight: 600;
  text-decoration: underline;
}

.order_info ul li.coupon_btn {
  margin: 0;
}

li.coupon_btn a {
  background-color: #ffffff;
  color: #454545;
  border: 1px solid #dddddd;
  padding: 11px 11px;
  border-radius: 5px;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  display: inline-block;
  margin-bottom: 11px;
  width: 100%;
}

li.coupon_btn p {
  line-height: 30px;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.order_info ul textarea#textareareview {
  resize: none;
  height: 150px;
  width: 100%;
  border: 1px solid #7bbb43;
  border-radius: 5px;
  padding-top: 20px;
  margin-top: 11px;
}

.order_info ul label {
  position: absolute;
  background-color: #ffffff;
  padding: 10px 23px;
  margin-left: 35px;
  color: #6aae39;
  font-size: 14px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: normal;
  margin-top: -7px;
}

p.textarea {
  text-align: right;
  font-size: 14px;
  margin-top: 11px;
  color: #161616;
}

.order_summary_detail {
  display: block;
  margin: 0;
  padding: 0;
}

.order_summary_detail h4 {
  font-size: 23px;
  text-transform: uppercase;
  color: #033d29;
  font-family: "All Round Gothic Demi";
  font-weight: 600;
  margin-bottom: 15px;
}

.order_dtl {
  display: block;
  margin: 0;
  padding: 15px;
  background-color: #f8f8f8;
}

.order_dtl p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000000;
  font-weight: 600;
  font-size: 17px;
}

.order_sel_list {
  width: 100%;
  float: left;
  display: block;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #cccccc;
}

.order_sel_list ul.order_sel {
  border-bottom: 1px solid #cccccc;
  width: 100%;
  float: left;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.order_sel_list ul.order_sel li {
  font-size: 16px;
  color: #454545;
  width: 30%;
}

.order_sel_list ul.order_sel li {
  font-size: 16px;
  color: #454545;
  width: 32%;
  float: left;
  list-style: none;
  display: block;
  padding-top: 11px;
}

.order_sel_list ul.order_sel li span {
  float: right;
  height: 36px;
  border-radius: 5px;
  width: 40px;
  background-color: #ffffff;
  padding: 11px;
  border: 1px solid #dddddd;
  text-align: center;
  color: #7bbb43;
  font-size: 19px;
  box-shadow: 2px 0px 29px #0000001a;
}

.profile_menu_bar .nav-tabs {
  border-bottom: none;
}

.order_sel_list ul.order_sel li:nth-child(2) {
  text-align: center;
  color: #7bbb43;
  font-size: 17px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  float: right;
}

.order_sel_list ul.order_sel li:nth-child(2) i {
  margin-right: -21px;
  margin-top: -5px;
  font-size: 24px;
}

.order_sel_list ul.order_sel li:last-child {
  padding-top: 0;
}

.order_no p {
  color: #373636;
  line-height: 30px;
  font-size: 15px;
}

.order_shp {
  width: 100%;
  display: block;
  float: left;
}

.order_shp ul {
  margin: 0;
  padding: 0;
}

.order_shp ul li:last-child {
  border: none;
}

.order_shp ul li span {
  width: 25%;
  float: right;
}

.order_shp ul li {
  width: 100%;
  list-style: none;
  float: left;
  padding: 18px 15px;
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 600;
  background-color: #f8f8f8;
  border-bottom: 1px solid #cccccc;
}

.services_page_section {
  display: block;
  padding: 70px 0px;
  margin: 0;
}

.services_page_section .side_bar {
  background-color: #ffffff;
  padding: 9px;
  padding: 0;
  box-shadow: 1px 0px 7px #0000001f;
  border-radius: 8px;
  display: block;
}

.services_page_section ul#myTab h4 {
  text-align: left;
  background-color: #ffffff;
  font-size: 20px;
  text-transform: uppercase;
  color: #003a25;
  padding: 20px 20px;
  border-bottom: 1px solid #e5e5e5;
}

.services_page_section ul#myTab li {
  width: 100%;
  list-style: none;
}

.services_page_section .side_bar li {
  border-bottom: 1px solid #e5e5e5;
}

.services_page_section ul#myTab li a {
  cursor: pointer;
  color: #151515;
  text-transform: capitalize;
  font-size: 16px;
  padding: 15px 25px;
  border-bottom: 1px solid #ffffff;
  margin-bottom: 1px;
  margin-right: 0;
  border-radius: 0;
}

.services_page_section ul#myTab ul.submenu_sec {
  background-color: #ffffff;
}

.services_page_section ul#myTab ul.submenu_sec li a {
  width: 100%;
  padding: 15px 25px;
  display: block;
}

.services_page_section ul#myTab ul.submenu_sec li a i {
  margin-right: 11px;
}

.services_page_section ul#myTab i.fa.fa-plus.pull-right {
  font-size: 12px;
  color: #315c45;
  margin-top: 7px;
}

.services_page_section ul#myTab ul.submenu_sec .submenu_sec2 li a {
  width: 100%;
  padding: 11px 25px;
  display: block;
}

.services_page_section ul#myTab ul.submenu_sec .submenu_sec2 li a {
  width: 100%;
  padding: 11px 25px;
  padding-left: 21px;
  display: block;
}

.submenu_sec2 {
  background-color: #ffffff;
}

.services_page_section ul#myTab ul.submenu_sec .submenu_sec2 li a i {
  font-size: 11px;
}

.services_page_section ul#myTab > li.active > a:focus {
  background-color: #74b944 !important;
  color: #ffffff;
  border-right: 4px solid #74b944;
}

.services_page_section ul#myTab > li > a:focus {
  background-color: #74b944 !important;
  color: #ffffff;
  border-right: 4px solid #74b944;
}

.services_page_section ul#myTab li.active a {
  background-color: #74b944 !important;
  color: #ffffff;
  border-right: 4px solid #74b944;
}

.services_page_section ul#myTab > li > a:hover {
  background-color: #74b944 !important;
  color: #ffffff;
  border-right: 4px solid #74b944;
}

.services_page_section ul#myTab ul.submenu_sec li a:hover {
  background-color: #74b944 !important;
  color: #ffffff;
  border-right: 4px solid #74b944;
}

.services_page_section ul#myTab ul.submenu_sec .submenu_sec2 li a:hover {
  background-color: #0c6645a6 !important;
  color: #ffffff;
  border-right: 4px solid #0c6645;
}

.services_page_section .side_bar ul#myTab {
  border-bottom: none;
}

.text_block_right_sec {
  display: block;
  margin-bottom: 25px;
  padding: 0;
  clear: both;
}

.carousel_slide {
  margin: 20px 0;
  display: inline-block;
}

.carousel_slide span.glyphicon.glyphicon-chevron-left {
  bottom: 60px;
  top: auto;
  color: #81bd45;
  font-size: 16px;
  left: 12px;
}

.carousel_slide span.glyphicon.glyphicon-chevron-right {
  bottom: 60px;
  top: auto;
  color: #81bd45;
  font-size: 16px;
  right: 12px;
}

.text_block_right_sec h2 {
  font-size: 30px;
  color: #0c6645;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc;
}

.text_block_right_sec h4 {
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
  color: #000000;
}

.text_block_right_sec p {
  font-size: 16px;
  line-height: 30px;
  color: #606060;
  clear: both;
}

.text_block_right_sec a {
  font-size: 19px;
  background-color: #74b944;
  border: 1px solid #74b944;
  border-radius: 5px;
  color: #ffffff;
  padding: 11px 25px;
  display: inline-block;
  margin-top: 20px;
}

.text_block_right_sec a:hover {
  background-color: #82BD45;
}

ul.serv_icon {
  margin: 0;
  padding: 0;
}

ul.serv_icon li {
  list-style: none;
  margin: 23px 0;
  padding-left: 0;
}

ul.serv_icon li p {
  font-size: 15px;
  line-height: 30px;
  color: #000000;
}

ul.serv_icon li p img {
  margin-right: 10px;
}

div#thumbCarousel {
  margin-top: 20px;
  width: 90%;
  margin: 20px auto;
  padding-left: 5px;
}

.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  margin: auto;
}

#thumbCarousel .thumb {
  margin: 10px 5px;
  background: #fff;
  display: inline-block;
}

#thumbCarousel .thumb:last-child {
  margin-right: 0;
}

.thumb:hover {
  cursor: pointer;
}

.thumb img {
  filter: grayscale(1);
  opacity: 0.5;
  border-radius: 15px;
}

.thumb img:hover {
  opacity: 1;
}

.thumb.active img {
  opacity: 1;
  border: 1px solid #1d62b7;
}

.carousel_slide .carousel-control.left {
  background: none;
  text-shadow: none;
  opacity: 1;
}

.carousel_slide .carousel-control.right {
  background: none;
  text-shadow: none;
  opacity: 1;
}

.carousel_slide .item img {
  width: 100%;
  height: auto;
  border-radius: 15px;
  filter: grayscale(1);
}

.schedule_section {
  display: block;
  padding: 70px 0;
  margin: 0;
  background-color: #f4fcee;
}

.hp_serv_form input {
  min-height: 48px;
  font-size: 14px;
}

.hp_serv_form input[type=checkbox] {
  min-height: auto;
}

.hp_serv_form label {
  margin: 0 9px;
  font-size: 16px;
  color: #343434;
}

.hp_serv_form .checkbox {
  margin: 30px 0;
}

.hp_serv_form button.main_btn {
  background-color: #315C45;
  border: 1px solid #315C45;
  font-size: 22px;
  color: #ffffff;
  padding: 8px 33px;
  border-radius: 5px;
  margin-top: 25px;
}

.accordion_one .load_btn a:hover,
.text_block_right_sec a:hover {
  background-color: #5dab26;
  border: 1px solid #5dab26;
}

.hp_serv_form button.main_btn:hover {
  background-color: #003a26;
}

.hp_serv_form {
  width: 75%;
  margin: 0 auto;
  text-align: center;
}

.rr_section {
  display: block;
  padding: 70px 0;
  margin: 0;
  background-color: #ffffff;
}

li.rr_block {
  list-style: none;
  padding: 35px;
  border-bottom: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}

li.rr_block:last-child,
li.rr_block:nth-child(3) {
  border-bottom: none;
}

.page_services_section .owl-carousel.owl-drag .owl-item {
  width: 5% !important;
  margin: 0 0.5%;
}

li.rr_block:nth-child(3) {
  border-right: 1px solid #ebebeb;
}

li.rr_block:nth-child(2),
li.rr_block:nth-child(4) {
  border-right: none;
}

.reviews_sec span i {
  color: #82bd45;
  margin: 0 1px;
}

.reviews_sec p {
  font-size: 16px;
  line-height: 33px;
  color: #606060;
  font-style: italic;
}

.services_faq_section {
  display: block;
  padding: 70px 0;
  margin: 0;
  background-color: #f7f8f9;
}

.accordion_one {
  width: 75%;
  margin: 0 auto;
  margin-top: 35px;
}

.accordion_one .panel .panel-heading a.collapsed:after {
  content: "";
  color: #82BD45;
}

.accordion_one .panel .panel-heading a:after,
.accordion_one .panel .panel-heading a.collapsed:after {
  font-family: "FontAwesome";
  font-size: 15px;
  text-align: center;
  float: right;
}

.accordion_one .panel .panel-heading a:after {
  content: "";
  color: #ffffff;
}

.accordion_one .panel .panel-heading a:after,
.accordion_one .panel .panel-heading a.collapsed:after {
  font-family: "FontAwesome";
  font-size: 24px;
  text-align: center;
  float: right;
}

.accordion_one a.collapsed {
  background-color: #ffffff;
  color: #161616;
}

.accordion_one a {
  width: 100%;
  display: block;
  font-size: 17px;
  padding: 25px;
  color: #ffffff;
  background-color: #74b944;
}

.accordion_one .panel-heading {
  background-color: #ffffff !important;
  padding: 0px;
  border-left: 2px solid #82BD45 !important;
  border: #ffffff;
}

.accordion_one .panel.panel-default {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

.accordion_one .text-accordion p {
  font-size: 15px;
  line-height: 33px;
  color: #161616;
}

.panel-body {
  border-top-color: transparent !important;
  margin-top: 15px;
}

.accordion_one .load_btn {
  display: block;
  text-align: center;
}

.accordion_one .load_btn a {
  display: inline-block;
  width: auto;
  padding: 10px 25px;
  border-radius: 5px;
  background-color: #74b944;
  border: 1px solid #74b944;
  font-size: 19px;
  font-weight: 400;
}

.serv_mob_view {
  display: none;
}

.services_page_section .sec_title_bar h2,
.services_page_section .sec_title_bar p {
  text-align: left;
}

.serv_pg_right_block {
  background-color: #ffffff;
  box-shadow: 2px 0px 29px #0000000d;
  padding: 25px;
}

.services_page_section ul#myTab li a.selected {
  background-color: #7bbb43;
  color: #ffffff;
}

.services_page_section ul#myTab ul#submenu-2 li a:hover,
.services_page_section ul#myTab ul#submenu-1 li a:hover {
  background-color: #f4fcee !important;
  color: #161616;
}

.services_page_section ul#myTab li a.selected i {
  color: #ffffff !important;
}

/*services page css end*/
/*confirm_pop*/
.confirm_pop .loginmodal-container {
  max-width: 499px;
  border: 4px solid #7bbb43;
  position: relative;
}

.confirm_pop_title_bar {
  text-align: center;
  display: block;
  margin: 0;
  padding: 29px 0 50px 0;
}

.confirm_pop_title_bar h1 {
  font-size: 26px;
  color: #003a25;
  font-family: "All Round Gothic Demi";
  font-weight: 600;
  text-transform: uppercase;
  margin: 20px 0px;
}

.confirm_pop_title_bar p {
  line-height: 30px;
  color: #434343;
  font-size: 16px;
}

.confirm_pop_title_bar p span {
  color: #6cac34;
}

.confirm_pop_title_bar p b {
  color: #292929;
}

.pop_eft {
  display: block;
  margin: 0;
  padding: 0;
}

.pop_eft ul {
  margin: 0;
  padding: 0;
}

.pop_eft ul li {
  position: absolute;
  list-style: none;
}

.pop_eft ul li:first-child {
  top: 0;
  left: 10px;
}

.pop_eft ul li:nth-child(2) {
  top: 0;
  right: 10px;
}

.pop_eft ul li:nth-child(3) {
  bottom: 30px;
  left: 25px;
}

.pop_eft ul li:last-child {
  right: 25px;
  bottom: 30px;
}

.pop_eft ul li img {
  width: auto;
  height: 25px;
}

.pop_eft ul li:nth-child(2) img {
  width: auto;
  height: 20px;
}

/**/
.b_order_total {
  width: 100%;
  background-color: #ffffff;
  margin: 30px 0;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 1px 0px 21px rgba(0, 0, 0, 0.07);
  display: block;
  float: left;
}

.b_order_total ul li {
  float: left;
  display: block;
  border: none;
  width: auto;
  padding: 15px;
}

.b_order_total ul li:first-child {
  width: 37%;
  text-align: center;
}

.b_order_total ul li p {
  font-size: 18px;
  color: #033d29;
}

.b_order_total ul li:nth-child(2) {
  width: 30%;
}

.b_order_total ul li:nth-child(2) span {
  font-size: 14px;
  color: #313232;
  margin-right: 15px;
}

.b_order_total ul li:nth-child(2) a.skip_btn {
  padding: 15px 25px;
  border: 1px solid #e3e3e3;
  border-radius: 5px;
  box-shadow: 2px 0 29px #5656562b;
  color: #003a25;
  font-weight: 600;
  font-size: 16px;
}

/* CSS Document */
@media only screen and (max-width: 1199px) {
  .container {
    width: 1024px !important;
    margin: 0 auto;
  }

  .menu_sec ul li {
    margin: 11px 16px;
  }

  .b_order_item.image {
    background-size: 450%;
  }

  .menu_sec ul li,
.menu_sec ul li:last-child {
    font-size: 14px;
  }

  .hp_add p {
    font-size: 14px;
  }

  .menu_sec {
    margin-top: 0;
  }

  .logo_sec img {
    height: 40px;
    width: auto;
  }

  .hp_add {
    margin-top: 12px;
  }

  .list_text p br {
    display: none;
  }

  .list_icon {
    width: 65px;
    height: 65px;
    box-shadow: 6px 9px 7px rgba(0, 0, 0, 0.15);
  }

  .list_text p {
    font-size: 13px;
  }

  li.why_list_sec {
    margin-bottom: 11px;
  }

  li.why_list_sec:nth-child(3) {
    left: 0;
  }

  .right_side_block li.why_list_sec:nth-child(3) {
    right: 0;
  }

  .logo_center {
    text-align: center;
  }

  .right_side_blog .blog_img,
.right_side_blog .blog_text {
    width: 50%;
  }

  .right_side_blog .blog_text span {
    margin-top: 0;
  }

  .right_side_blog .blog_text {
    padding: 15px;
  }

  .right_side_blog .blog_text h4 {
    font-size: 16px;
    line-height: 24px;
    margin: 9px 0;
  }

  .right_side_blog .blog_text a,
.blog_text a {
    margin-top: 7px;
  }

  .blog_text h4 {
    font-size: 18px;
    margin-bottom: 7px;
  }

  .app_block button.app_btn {
    padding: 0 15px;
  }

  .blog_text p {
    line-height: 25px;
  }

  .cust_block {
    padding: 15px;
    margin-bottom: 52px;
  }

  .cust_text {
    padding: 0;
  }

  .cust_text span p {
    font-size: 13px;
  }

  li#ft_sec,
li#ft_sec3,
li#ft_sec5 {
    width: 20%;
  }

  .inner_banner_section {
    min-height: 300px;
  }

  .promise_text p br {
    display: none;
  }

  .promise_text h4,
.benefit_list li h4,
.b_order_item h4 {
    font-size: 16px;
  }

  .benefit_list {
    margin: 0;
  }

  .benefit_list li {
    margin: 0;
  }

  .career_join_section .ab_about_title {
    margin-top: 35px;
  }

  .main_title_bar p br {
    display: none;
  }

  .about_text_sec {
    padding-top: 0;
  }

  .about_text_sec p,
.main_title_bar p,
.pricing_title_bar p {
    font-size: 15px;
    line-height: 27px;
    margin-bottom: 12px;
  }

  .team_block {
    width: 21.6%;
  }

  .locate_form .form_group {
    display: inline-block;
  }

  .locate_form input {
    width: 100%;
  }

  .locate_form select {
    width: 160px;
  }

  .locate_form label.control-label {
    font-size: 16px;
  }

  .left_sidebar_block {
    padding-right: 15px;
  }

  .blog_post_block2 .post_content {
    padding: 0;
  }

  .blog_post_block2 .post_content h4 {
    margin-bottom: 2px;
  }

  .post_meta a,
.post_content span {
    font-size: 14px;
  }

  .media_page li .med_text h4 br {
    display: none;
  }

  .franchise_page_section .ab_about_title h2 {
    margin-bottom: 7px;
  }

  ul.list_franch li {
    margin: 0;
  }

  .franchise_form .btn {
    width: 30%;
  }

  .pg_text_block p,
.pg_add_sec p {
    font-size: 15px;
  }

  .b_order_item ul li p {
    font-size: 15px;
  }

  .search_section {
    width: 80%;
  }

  .covid_text p {
    font-size: 15px !important;
  }

  .covid_text p br {
    display: none;
  }

  .covid_text span {
    font-size: 24px;
  }

  .profile_form,
.profile_setting_block {
    width: 80%;
  }

  .address_list_sec ul li p,
.setting_sec li p {
    font-size: 15px;
  }

  .prog_step ul li {
    padding-right: 23%;
  }

  .date_time_block {
    width: 60%;
  }

  .top_tab_bar a {
    padding: 20px 18px;
  }
}
@media screen and (max-width: 1024px) {
  .container {
    width: 980px !important;
    margin: 0 auto;
  }

  .menu_sec ul li {
    margin: 7px 13px;
  }

  .hp_banner_section {
    min-height: 400px;
  }

  .hp_banner_form {
    width: 65%;
  }

  .md_button button {
    font-size: 16px;
  }

  .hp_banner_block {
    margin-top: 135px;
  }

  .hp_banner_tilte h1,
.inner_banner_tilte h1 {
    font-size: 40px;
  }

  .pricing_title_bar p br {
    display: none;
  }

  .hp_add {
    margin-top: 7px;
  }

  .right_side_blog .blog_text h4 {
    font-size: 16px;
    line-height: 21px;
    margin: 7px 0;
  }

  .right_side_blog .blog_text p {
    line-height: 17px;
  }

  .hp_why_us_section li.why_list_sec .col-md-8 {
    padding: 0;
  }

  .left_title_bar p,
.app_block p,
.app_block .app_sec p a,
.main_title_bar p,
.app_sec p a,
.ab_about_title p,
.ab_about_title a {
    font-size: 15px;
  }

  .text_sec h4,
.ft_link h4,
.blog_text h4,
.app_block h4,
.career_team_text h4,
.media_page li .med_text h4,
.pg_text_block h4 {
    font-size: 16px;
  }

  .sec_title_bar h2,
.main_title_bar h2,
.left_title_bar h2,
.ab_about_title h2,
.pg_text_block h2,
.profile_title h2 {
    font-size: 26px;
  }

  .main_title_bar h6,
.tab_bar a {
    font-size: 18px;
  }

  .ab_main_section .main_title_bar p br {
    display: none;
  }

  .team_block {
    width: 22.6%;
    margin: 10px;
    padding: 15px 4px;
  }

  .ab_about_title {
    margin-top: 0;
  }

  .team_block .team_text h4 {
    font-size: 14px;
  }

  .team_block .team_text p {
    font-size: 13px;
  }

  .locate_form .form_group {
    display: inline-block;
  }

  .locate_form select {
    width: 140px;
  }

  .locate_form input {
    width: 100%;
    padding-left: 35px;
  }

  .locate_form .city_search span {
    left: 11px;
  }

  .covid_clean_section .covid_text {
    text-align: left;
    padding: 0 30px;
  }

  .covid_text p,
.post_content p,
.post_list li,
.cond_list_sec ul li {
    font-size: 14px;
  }

  .post_content h4,
.left_sidebar h4,
.news_letter h4,
.news_letter .btn.btn-large {
    font-size: 16px;
  }

  .post_description a,
.categories_bar li a,
.arch_bar li a {
    font-size: 14px;
    line-height: 25px;
  }

  .date_time p {
    font-size: 13px;
  }

  .categories_bar li a:before {
    width: 25px;
    height: 25px;
    padding-top: 0px;
    font-size: 16px;
  }

  .news_letter form input {
    font-size: 14px;
  }

  .blog_post_block2 .post_content {
    padding: 0;
  }

  .blog_post_block2 .post_content h4 {
    margin-bottom: 2px;
  }

  .post_meta a,
.post_content span {
    font-size: 14px;
  }

  .media_page nav a.nav-link {
    font-size: 18px;
  }

  .new_post_text h4,
.media_text_block h4 {
    font-size: 16px;
  }

  .new_post_text p,
.new_post_text span,
.media_text_block p,
.media_text_block span {
    font-size: 14px;
    line-height: 27px;
  }

  .new_post_text a {
    font-size: 15px;
  }

  .pg_add_sec h4 {
    font-size: 18px;
  }

  .b_order_item ul li p {
    font-size: 14px;
  }

  .pr_text p {
    font-size: 18px;
  }

  .covid_video_text {
    padding: 40px 0;
  }

  .covid_video_text p {
    font-size: 15px;
    line-height: 35px;
  }

  .job_block {
    padding: 25px 15px;
  }

  .job_block h4 {
    font-size: 18px;
  }

  .job_block ul li:first-child {
    margin-right: 12px;
    padding-right: 12px;
  }

  .job_block ul li,
.career_icon_text p {
    font-size: 15px;
  }

  .job_block a {
    font-size: 15px;
  }

  .career_icon_text h4 {
    font-size: 16px;
  }

  .prog_step ul li {
    padding-right: 24%;
  }

  .prog_step ul li p {
    font-size: 15px;
  }
}
@media screen and (max-width: 980px) {
  .container {
    width: 700px !important;
    margin: 0 auto;
  }

  .logo_sec {
    margin-left: 16px;
    margin-bottom: 10px;
  }

  .page_services_section .owl-carousel.owl-drag .owl-item {
    width: 2.4% !important;
  }

  .page_services_section .owl-carousel .nav-btn {
    top: 22% !important;
  }

  .page_services_section .owl-carousel.owl-drag .owl-item .service_text_sec p {
    font-size: 11px;
  }

  .covid_video:before {
    width: 150px;
    height: 160px;
    padding: 45px;
  }

  .covid_video_text {
    padding: 0;
  }

  .menu_sec {
    display: none;
  }

  .hp_banner_section {
    min-height: 350px;
  }

  .hp_banner_tilte h1,
.inner_banner_tilte h1 {
    font-size: 30px;
  }

  .hp_banner_form {
    width: 85%;
  }

  .hp_banner_block {
    margin-top: 50px;
  }

  html {
    overflow: auto;
  }

  .list_text p,
.left_title_bar p,
.app_block p,
.main_title_bar p,
.benefit_list li p,
.career_team_text p,
.ab_about_title p,
.media_page li .med_text p,
.store_pg_text p,
ul.work_list li {
    font-size: 14px;
  }

  .store_detail_sec {
    display: block;
    padding: 20px 50px;
    margin-top: 35px;
  }

  .bn_btn a {
    margin-top: 11px;
    padding: 11px 25px;
    font-size: 14px;
  }

  .pg_text_block p {
    font-size: 14px;
  }

  .pg_add_sec h4 {
    font-size: 18px;
  }

  .sec_title_bar h2,
.main_title_bar h2,
.left_title_bar h2,
.ab_about_title h2,
.pg_text_block h2,
.store_pg_text h2,
.profile_title h2 {
    font-size: 20px;
    line-height: 36px;
    font-family: "All Round Gothic";
  }

  .store_pg_text h2 br {
    display: none;
  }

  .right_block {
    padding: 30px 0px;
    display: block;
  }

  .app_block .form-group {
    margin: 25px 0;
    padding: 0 5px;
  }

  .app_block button.app_btn {
    font-size: 15px;
  }

  .main_title_bar p br,
.career_team_text p br {
    display: none;
  }

  .blog_box {
    margin-bottom: 30px;
  }

  .career_join_section .ab_about_title {
    margin: 0;
    padding: 0;
  }

  .ab_about_title h2 {
    margin-bottom: 0;
  }

  .right_side_blog .blog_text h4 {
    font-size: 16px;
    line-height: 30px;
    margin: 15px 0;
  }

  .right_side_blog .blog_text p {
    line-height: 27px;
  }

  .right_side_blog .blog_text {
    padding: 35px 20px;
  }

  .ft_sec {
    width: 30%;
    margin-bottom: 30px;
  }

  li#ft_sec {
    width: 65%;
    padding-left: 30px;
  }

  li#ft_sec3 {
    width: 30%;
    padding-left: 0px;
  }

  li#ft_sec4 {
    width: 30%;
    padding-left: 30px;
  }

  li#ft_sec5 {
    width: 30%;
  }

  li.why_list_sec {
    margin-bottom: 20px;
  }

  .list_icon {
    margin: 0 auto;
  }

  .list_text {
    display: block;
    text-align: center;
    margin-top: 12px;
    min-height: 49px;
  }

  li.why_list_sec:nth-child(2) {
    padding-right: 0;
  }

  .right_side_block li.why_list_sec:nth-child(2) {
    padding: 0;
  }

  .logo_center {
    text-align: center;
    margin-top: 142px;
  }

  .benefit_img {
    text-align: center;
    margin-bottom: 30px;
  }

  li.why_list_sec:first-child {
    position: relative;
    top: 0;
    left: 0;
  }

  li.why_list_sec:nth-child(2),
li.why_list_sec:nth-child(4),
li.why_list_sec:nth-child(5) {
    left: 0;
  }

  .center_side_block {
    display: none;
  }

  .right_side_block li.why_list_sec:first-child,
.right_side_block li.why_list_sec:nth-child(2),
.right_side_block li.why_list_sec:nth-child(4),
.right_side_block li.why_list_sec:nth-child(5) {
    right: 0;
    display: contents;
  }

  .hp_why_us_section li.why_list_sec .col-md-8 {
    padding: 0 15px;
  }

  .list_text p br {
    display: block;
  }

  .right_side_block .list_text {
    text-align: center;
    justify-content: flex-start;
  }

  /*responsive menu*/
  .hp_mob_header {
    display: block;
    padding: 11px 0;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 9999;
  }
  .hp_mob_header .location-disabled-strip {
    display: flex;
    height: 35px;
    background-color: #fff3cd;
    padding: 20px;
  }

  /*responsive menu*/
}
@media screen and (max-width: 980px) and (max-width: 550px) {
  .hp_mob_header .location-disabled-strip {
    margin-top: 25px;
  }
}
@media screen and (max-width: 980px) {
  .hp_mob_header .location-disabled-strip .mesg-box {
    font-size: 10px;
    font-weight: 400;
    align-items: center;
    text-align: center;
    flex: 1 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 980px) and (max-width: 550px) {
  .hp_mob_header .location-disabled-strip .mesg-box {
    padding: 0px 10px;
  }
}
@media screen and (max-width: 980px) {
  .hp_mob_header .location-disabled-strip .action-box {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
  }
}
@media screen and (max-width: 980px) {
  header.hp_mob_header .row .col-sm-4.mob_header {
    float: right;
    display: flex;
    align-items: center;
    width: 60px;
    margin-right: 8px;
    z-index: 9;
  }
}
@media screen and (max-width: 980px) {
  .header_section {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .logo_sec img {
    height: 50px;
    width: auto;
  }
}
@media screen and (max-width: 980px) {
  .hp_add span {
    position: absolute;
    right: 25px;
    bottom: 1px;
    font-size: 12px;
    color: #003a25;
  }
}
@media screen and (max-width: 980px) {
  .mob_header {
    display: block;
  }
}
@media screen and (max-width: 980px) {
  .hp_add {
    margin-top: 14px;
  }
}
@media screen and (max-width: 980px) {
  .col-lg-3.col-md-3.col-sm-12.col-xm-12.logo_sec_info {
    display: inline-block;
  }
}
@media screen and (max-width: 980px) {
  .logo_sec img {
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 980px) {
  .menu_btn_open {
    float: right;
    margin-top: 0;
    margin-right: 0;
    position: relative;
    top: 3px;
    z-index: 1;
    right: 0px;
  }
}
@media screen and (max-width: 980px) {
  .menu_open {
    font-size: 24px !important;
    color: #315c46;
    cursor: pointer;
  }
}
@media screen and (max-width: 980px) {
  .menu_btn_close {
    float: right;
    margin-right: 20px;
    margin-top: 8px;
  }
}
@media screen and (max-width: 980px) {
  .fa.fa-times.menu_close {
    font-size: 21px;
    color: #80ba4d;
    cursor: pointer;
  }
}
@media screen and (max-width: 980px) {
  .topnav.mob_menu.active {
    right: 0%;
    top: 0px;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
@media screen and (max-width: 980px) {
  .site_hp.active {
    overflow-y: hidden;
  }
}
@media screen and (max-width: 980px) {
  .mob_menu {
    position: fixed;
    right: -100%;
    top: 0px;
    background-color: #ffffff;
    width: 402px;
    z-index: 999;
    height: auto;
    display: block;
  }
}
@media screen and (max-width: 980px) {
  .mob_menu ul {
    width: 60%;
    float: right;
    padding: 35px 0px 0px 0px;
    background: white;
    margin: 0 -37px 0px 0px;
    border-radius: 5% 5%;
  }
}
@media screen and (max-width: 980px) {
  .mob_menu li {
    padding: 7px 7px;
    border-bottom: 1px solid transparent;
    list-style: none;
  }
}
@media screen and (max-width: 980px) {
  .mob_menu a {
    color: #003A25;
    display: flex;
    font-size: 15px;
    text-align: left;
    font-family: "All Round Gothic";
  }
}
@media screen and (max-width: 980px) {
  .mob_menu li.active a {
    color: #80ba4d;
    font-weight: 600;
  }
}
@media screen and (max-width: 980px) {
  .mob_menu {
    width: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 980px) {
  .mob_menu a:hover {
    color: #80ba4d;
  }
}
@media screen and (max-width: 980px) {
  .mod_logo_section {
    display: block;
  }
}
@media screen and (max-width: 980px) {
  .logo_section {
    display: none;
    margin-top: 15px;
  }
}
@media screen and (max-width: 980px) {
  .mod_logo_section img {
    width: 100%;
    height: auto;
  }
}
@media screen and (max-width: 980px) {
  .blog_text h4 {
    line-height: 30px;
  }
}
@media screen and (max-width: 980px) {
  .team_block {
    width: 44.6%;
    margin: 10px 18px;
    padding: 15px 4px;
  }
}
@media screen and (max-width: 980px) {
  .ab_story_block nav a {
    margin: 0 10%;
  }
}
@media screen and (max-width: 980px) {
  .ab_story_block nav {
    margin: 0;
  }
}
@media screen and (max-width: 980px) {
  .ab_promise {
    padding: 0;
  }
}
@media screen and (max-width: 980px) {
  .promise_text p {
    font-size: 14px;
    line-height: 25px;
  }
}
@media screen and (max-width: 980px) {
  .promise_bottom .promise_text {
    padding: 0;
  }
}
@media screen and (max-width: 980px) {
  .inner_banner_section {
    min-height: 245px;
  }
}
@media screen and (max-width: 980px) {
  .gg_map iframe {
    height: 350px;
  }
}
@media screen and (max-width: 980px) {
  .locate_search {
    position: relative;
    left: 0;
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .locate_form label.control-label,
.locate_form select {
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  .locate_form input::placeholder {
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  .locate_form label.control-label {
    margin-right: 6px;
  }
}
@media screen and (max-width: 980px) {
  .locate_form select {
    width: 82px;
    padding: 0 6px;
  }
}
@media screen and (max-width: 980px) {
  .locate_form .select::after {
    padding: 6px;
    top: 11px;
  }
}
@media screen and (max-width: 980px) {
  .locate_form button.btn.btn-default {
    font-size: 15px;
  }
}
@media screen and (max-width: 980px) {
  .locate_form input::placeholder {
    font-size: 13px;
  }
}
@media screen and (max-width: 980px) {
  .locate_form input {
    width: 100%;
    padding-left: 35px;
  }
}
@media screen and (max-width: 980px) {
  .covid_clean_section .covid_text {
    text-align: left;
    padding: 0 15px;
  }
}
@media screen and (max-width: 980px) {
  .covid_text h4 {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  span.point_sec {
    width: 30px;
    height: 30px;
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .eft2 {
    right: 30px;
  }
}
@media screen and (max-width: 980px) {
  .post_quote {
    padding: 30px 30px 30px 30px;
  }
}
@media screen and (max-width: 980px) {
  .post_quote p {
    font-size: 14px;
    line-height: 27px;
  }
}
@media screen and (max-width: 980px) {
  .post_quote h5 {
    font-size: 14px;
    margin-top: 3px;
  }
}
@media screen and (max-width: 980px) {
  .post_quote p:before {
    font: normal normal normal 18px/1 FontAwesome;
    top: 6px;
    left: -23px;
  }
}
@media screen and (max-width: 980px) {
  .blog_post_block2 .post_image img {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .media_page li .med_text p br {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .franchise_page_section .ab_about_title {
    margin-top: 30px;
  }
}
@media screen and (max-width: 980px) {
  .franchise_form {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .media_img_block:before {
    width: 270px;
    height: 270px;
  }
}
@media screen and (max-width: 980px) {
  .new_block {
    margin: 25px auto;
  }
}
@media screen and (max-width: 980px) {
  .pg_text_block p,
.pg_add_sec p,
.pg_add_sec li a {
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  #flex_add {
    flex: 1;
  }
}
@media screen and (max-width: 980px) {
  .quantity input.minus,
.quantity input.plus,
.quantity input.input-text.qty.text_input {
    width: 25px;
  }
}
@media screen and (max-width: 980px) {
  .b_order_item ul li {
    padding: 0 8px;
  }
}
@media screen and (max-width: 980px) {
  .b_order_item {
    padding: 0 5px;
  }
}
@media screen and (max-width: 980px) {
  input.input-text.qty.text_input {
    width: 38px !important;
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  .search_section {
    width: 100%;
    padding-right: 10%;
  }
}
@media screen and (max-width: 980px) {
  button.searchButton,
button.searchButton {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .select_city,
.select_store {
    width: 36%;
  }
}
@media screen and (max-width: 980px) {
  .covid_text h4 span {
    font-size: 20px;
    margin-right: 7px;
  }
}
@media screen and (max-width: 980px) {
  .covid_text p br {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .job_block {
    padding: 25px 15px;
    margin-top: 0;
  }
}
@media screen and (max-width: 980px) {
  .join_block .career_icon_text {
    min-height: 218px;
  }
}
@media screen and (max-width: 980px) {
  .career_join_section {
    background-color: #f4fcee;
    padding: 50px 0;
    margin: 0;
  }
}
@media screen and (max-width: 980px) {
  .user_name {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .order_history_block {
    width: 100%;
    margin-left: 0;
    float: left;
  }
}
@media screen and (max-width: 980px) {
  .order_delivered_list ul.order_id,
.order_item_sec ul.order_item_id,
.order_item_sec h4,
.lc_sec p {
    padding-left: 0;
  }
}
@media screen and (max-width: 980px) {
  ul.gst_code li {
    padding: 0 3px;
  }
}
@media screen and (max-width: 980px) {
  .lc_sec p br {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .profile_form,
.profile_setting_block,
.user_add_book {
    width: 100%;
    margin: 0;
  }
}
@media screen and (max-width: 980px) {
  .select_pro_sec.gander_select {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .profile_form form button#signupSubmit,
.new_address_btn button,
.slot_select ul li {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .profile_title {
    width: 70%;
  }
}
@media screen and (max-width: 980px) {
  .address_list_sec ul li h4,
.setting_sec li h4,
.slot_block .slot_title h4,
div.zabuto_calendar .table tr.calendar-month-header th span {
    font-size: 18px;
  }
}
@media screen and (max-width: 980px) {
  .setting_sec li p br {
    display: none;
  }
}
@media screen and (max-width: 980px) {
  .setting_sec li h5 {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .address_list_sec ul li button {
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  .prog_step ul li {
    padding-right: 21%;
  }
}
@media screen and (max-width: 980px) {
  .prog_step ul li p {
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  .add_new_form.active {
    width: 100%;
  }
}
@media screen and (max-width: 980px) {
  .date_time_block {
    width: 85%;
  }
}
@media screen and (max-width: 980px) {
  .covid_video_block {
    width: 80%;
  }
}
@media screen and (max-width: 980px) {
  .covid_page_video_section:before {
    height: 60%;
  }
}
@media screen and (max-width: 980px) {
  .covid_video_block span {
    top: 35%;
  }
}
@media screen and (max-width: 980px) {
  .left_tab_bar ul#myTab li a {
    font-size: 15px;
    padding: 15px 6px;
  }
}
@media screen and (max-width: 980px) {
  .left_tab_bar ul#myTab h4 {
    font-size: 16px;
    padding: 15px 7px;
  }
}
@media screen and (max-width: 980px) {
  .pricing_teg_line h4 {
    font-size: 16px;
  }
}
@media screen and (max-width: 980px) {
  .tab_pricing_list ul li {
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  .top_tab_bar a img {
    margin-right: 2px;
    margin-left: 0px;
    height: 25px;
  }
}
@media screen and (max-width: 980px) {
  .top_tab_bar a {
    padding: 20px 4.9px;
    font-size: 14px;
  }
}
@media screen and (max-width: 980px) {
  .pricing_main_section div#myTabContent {
    padding-right: 0;
    padding-left: 5px;
  }
}
@media screen and (max-width: 720px) {
  .b_order_item.image {
    padding-right: 23% !important;
  }
}
@media screen and (max-width: 767px) {
  .franchise_page_section2 .ab_about_title {
    margin-top: -10px;
  }

  .locate_page_section {
    box-shadow: 5px 0px 16px rgba(0, 0, 0, 0);
    background-color: #eee;
  }

  .franchise_form_section {
    background: #eee;
  }

  .franchise_page_section {
    background: #eee;
  }

  .franchise_page_section2 {
    background-color: #eee;
  }

  .container {
    width: 400px !important;
    margin: 0 auto;
  }

  .select_pro_sec {
    box-shadow: 2px 0px 29px rgba(87, 87, 87, 0);
    background-color: #ccc8c8;
  }

  .user_pro {
    margin: 10px;
  }

  .b_order_item.image {
    background-color: #ffffff;
    height: 89px !important;
    background-size: 350px;
    background-repeat: no-repeat;
  }

  .b_order_item.image.Premium.Laundry.Kg {
    background-position: 5% 0%;
  }

  .b_order_item.image.Laundry.\-.Wash.\&.Fold {
    background-position: 5% 30%;
  }

  .b_order_item.image.Laundry.\-.Wash.\&.Iron {
    background-position: 74% 96%;
    background-size: 415px;
  }

  .profile_right_block {
    background: white;
    padding: 2% 5%;
    margin-bottom: 30px;
  }

  .order_summary_pg_section .order_summary_pg button.apply_coupon_btn {
    width: 100% !important;
    margin-top: 5px;
    margin-left: 0px !important;
  }

  .profile_right_block .profile_title {
    text-align: left;
    margin-bottom: -20px;
    margin-top: 2px;
  }

  .col-md-6, .col-sm-12 {
    padding-left: 14px;
  }

  .pro-pad {
    padding-right: 22px;
  }

  section.career_team_section .uc_img_item .ng-image-slider .ng-image-slider-container .main .main-inner {
    height: 40% !important;
  }

  section.career_team_section .uc_img_item .ng-image-slider .ng-image-slider-container .next,
section.career_team_section .uc_img_item .ng-image-slider .ng-image-slider-container .prev {
    display: none;
  }

  section.career_team_section .uc_img_item {
    height: 245px;
  }

  .career_benefit_section {
    padding: 0;
  }

  .career_team_section {
    padding: 30px 0 0;
  }

  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light.bg-primary.text-white {
    background: green !important;
    width: 2.8em !important;
  }

  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day,
.date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light {
    width: 2.8em !important;
  }

  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-weekday.small {
    width: 100%;
  }

  .logo_sec img {
    height: auto;
    width: 100%;
  }

  .locate_form .form_group {
    margin: 11px 0;
  }

  .user_name {
    margin-top: 0px;
    margin-bottom: -10px;
    padding: 0 15px;
  }

  .profile_menu_bar nav.spy h4 {
    padding: 0 0 15px 15px;
  }

  .covid_page_video_section:before {
    height: 68.3%;
  }

  .about_img_sec:before,
.covid_video:before {
    padding: 45px;
    width: 50%;
    height: 100%;
  }
}
@media screen and (max-width: 767px) and (max-width: 420px) {
  .about_img_sec::before {
    height: 90%;
    margin: 11px;
    padding: 45px 0px 45px 0px;
  }
}
@media screen and (max-width: 767px) {
  .left_side_block,
.right_side_block {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .covid_video_text {
    padding: 0px 0;
    margin-top: 20px;
  }
}
@media screen and (max-width: 767px) {
  .covid_video_text p {
    line-height: 35px;
  }
}
@media screen and (max-width: 767px) {
  .join_block .career_icon_text {
    min-height: auto;
  }
}
@media screen and (max-width: 767px) {
  .page_bn_btn a.page_next_btn {
    width: fit-content !important;
    padding: 6px 9px 3px 21px;
    box-shadow: none;
  }
}
@media screen and (max-width: 767px) {
  .join_block {
    margin: 11px 0;
  }
}
@media screen and (max-width: 767px) {
  .date_time_block {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .career_team_section button.slick-prev {
    width: 35px;
    height: 35px;
    left: 10px;
  }
}
@media screen and (max-width: 767px) {
  .job_block h4 {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .career_team_section button.slick-next {
    width: 35px;
    height: 35px;
    right: 10px;
  }
}
@media screen and (max-width: 767px) {
  .covid_text p br {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .covid_text h4 span {
    font-size: 20px;
  }
}
@media screen and (max-width: 767px) {
  .hp_add {
    margin-top: 10px;
  }
}
@media screen and (max-width: 767px) {
  .menu_btn_open {
    top: 4px;
  }
}
@media screen and (max-width: 767px) {
  .hp_add span {
    right: 0;
  }
}
@media screen and (max-width: 767px) {
  .right_side_block {
    padding-left: 15px;
  }
}
@media screen and (max-width: 767px) {
  .right_side_blog .blog_img,
.right_side_blog .blog_text {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  li#ft_sec5,
li#ft_sec4,
li#ft_sec3,
li#ft_sec,
.ft_sec {
    width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .logo_center {
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0;
  }
}
@media screen and (max-width: 767px) {
  .hp_main_section li {
    right: 50px;
  }
}
@media screen and (max-width: 767px) {
  .right_side_block .list_text {
    text-align: center;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 767px) {
  .md_button button {
    font-size: 12px;
    min-height: 37px;
  }
}
@media screen and (max-width: 767px) {
  .locat_sec {
    width: 50%;
    padding: 0 25px;
  }
}
@media screen and (max-width: 767px) {
  .copy_right {
    margin-top: 30px;
    padding-top: 20px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .copy_right_text ul {
    float: unset;
    margin-top: 14px;
  }
}
@media screen and (max-width: 767px) {
  .copy_right_text ul li a,
.copy_right_text p,
.covid_video_text p {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .hp_banner_form {
    width: 100%;
    padding: 3px;
    height: 35px;
  }
}
@media screen and (max-width: 767px) {
  .md_locate {
    width: 30%;
    float: left;
    text-align: right;
    padding-right: 25px;
    padding-top: 5px;
  }
}
@media screen and (max-width: 767px) {
  .md_form input {
    padding: 3px 20px;
    min-height: 27px;
  }
}
@media screen and (max-width: 767px) {
  .md_locate span {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .md_locate {
    padding-right: 7px;
  }
}
@media screen and (max-width: 767px) {
  .hp_banner_tilte h1,
.inner_banner_tilte h1 {
    font-size: 21px;
  }
}
@media screen and (max-width: 767px) {
  .md_form i {
    font-size: 14px;
    left: 4px;
    top: 8px;
  }
}
@media screen and (max-width: 767px) {
  .md_button button {
    padding: 7px 0;
    min-height: 26px;
  }
}
@media screen and (max-width: 767px) {
  .hp_banner_section {
    min-height: 270px;
    background-position: inherit;
  }
}
@media screen and (max-width: 767px) {
  div#locat_sec1 {
    width: 100%;
    padding: 0 30px;
  }
}
@media screen and (max-width: 767px) {
  div#locat_sec1 li {
    width: 50%;
    float: left;
  }
}
@media screen and (max-width: 767px) {
  div#locat_sec1 li:nth-child(even) {
    margin-left: 15px;
    width: 44%;
  }
}
@media screen and (max-width: 767px) {
  div#locat_sec1 a {
    text-align: center;
    display: inline-block;
    margin-top: 30px;
    clear: both;
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .center_side_block {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .covid_video_block {
    width: 100%;
    padding: 7px;
  }
}
@media screen and (max-width: 767px) {
  .list_text p {
    min-height: 50px;
    line-height: 25px;
    font-size: 13px;
  }
}
@media screen and (max-width: 767px) {
  .uclean_img_sec {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .benefit_list li {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .inner_banner_section {
    min-height: 200px;
  }
}
@media screen and (max-width: 767px) {
  .about_img_sec {
    margin-bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .about_text_sec p,
.main_title_bar p {
    font-size: 14px;
    line-height: 27px;
    margin-bottom: 42px;
  }
}
@media screen and (max-width: 767px) {
  .high-rated {
    margin-bottom: 84px !important;
  }
}
@media screen and (max-width: 767px) {
  .promise_bottom .promise_text {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .promise_bottom {
    margin: 15px 0;
  }
}
@media screen and (max-width: 767px) {
  .line_sec {
    margin: 35px auto;
  }
}
@media screen and (max-width: 767px) {
  .ab_story_block nav a {
    margin: 0 2px;
  }
}
@media screen and (max-width: 767px) {
  .ab_about_img {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .team_block {
    width: 100%;
    margin: 10px 10px;
    padding: 15px 4px;
  }
}
@media screen and (max-width: 767px) {
  .team_block .team_text p {
    font-size: 13px;
    min-height: 33px;
  }
}
@media screen and (max-width: 767px) {
  .locate_form label.control-label {
    width: 100%;
    margin-bottom: 12px;
    font-size: 15px !important;
  }
}
@media screen and (max-width: 767px) {
  .locate_form .form_group,
.locate_form .select,
.locate_form select,
.city_search,
.locate_form input,
.locate_form button.btn.btn-default {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .locate_form button.btn.btn-default {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 767px) {
  .locate_search {
    margin-top: 35px;
  }
}
@media screen and (max-width: 767px) {
  .locate_list li a {
    font-size: 14px;
    padding: 11px 21px;
  }
}
@media screen and (max-width: 767px) {
  .locate_list li p {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .locate_form select {
    padding: 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .ab_mid_sectopn .promise_text {
    margin: 25px 0;
  }
}
@media screen and (max-width: 767px) {
  .covid_block_1,
.covid_block_2 {
    display: block;
  }
}
@media screen and (max-width: 767px) {
  .covid_block_sec:before {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .covid_clean_section .covid_text {
    margin-top: 30px;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  span.point_sec {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .covid_block_2 span.point_sec {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .eft2 {
    right: 30px;
    bottom: 30px;
  }
}
@media screen and (max-width: 767px) {
  .left_sidebar_block {
    margin-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  .tags_bar a {
    padding: 11px 15px;
  }
}
@media screen and (max-width: 767px) {
  .media_page nav a.nav-link {
    font-size: 16px;
  }
}
@media screen and (max-width: 767px) {
  .med_icon {
    margin-top: 30px;
  }
}
@media screen and (max-width: 767px) {
  .franchise_form .btn {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  .franchise_page_section2 .ab_about_title {
    padding-right: 0;
  }
}
@media screen and (max-width: 767px) {
  .new_block {
    width: 100%;
    margin: 25px auto;
  }
}
@media screen and (max-width: 767px) {
  .media_img_block {
    padding: 15px 0px 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .media_img_block:before {
    width: 130px;
    height: 130px;
    padding: 25px;
  }
}
@media screen and (max-width: 767px) {
  .media_text_block {
    padding-left: 0;
  }
}
@media screen and (max-width: 767px) {
  .cond_list_sec ul li {
    margin-left: 15px;
  }
}
@media screen and (max-width: 767px) {
  .tab_bar a#nav-home-tab:before {
    right: -22px;
    width: 1px;
    top: 19px;
    height: 23px;
  }
}
@media screen and (max-width: 767px) {
  .b_order_list {
    width: 75%;
    margin: 15px auto !important;
    display: block;
    border-bottom: 3px solid #7bbb43;
    border-right: none;
  }
}
@media screen and (max-width: 767px) {
  .b_order_item {
    padding: 0;
    margin-bottom: 0px;
  }
}
@media screen and (max-width: 767px) {
  #flex_img img {
    border-radius: 5px;
    width: 85%;
    height: 110px;
  }
}
@media screen and (max-width: 767px) {
  #flex_img {
    flex: auto;
    height: auto;
    width: auto;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .b_order_list h4 {
    font-size: 18px !important;
    font-weight: 600;
    color: #033d29;
    text-transform: none !important;
  }
}
@media screen and (max-width: 767px) {
  .b_order_list div#flex_add {
    margin-top: 10% !important;
  }
}
@media screen and (max-width: 767px) {
  .b_order_list {
    padding-bottom: 0;
  }
}
@media screen and (max-width: 767px) {
  div#flex_text ul li {
    border-right: none !important;
  }
}
@media screen and (max-width: 767px) {
  .col-lg-12.col-md-12.col-sm-12.col-xs-12.margin_top {
    padding: 0px;
    margin-top: -35px;
  }
}
@media only screen and (max-width: 767px) and (max-width: 767px) and (min-width: 415px) {
  .col-lg-12.col-md-12.col-sm-12.col-xs-12.margin_top {
    margin-top: 25px;
  }
}
@media screen and (max-width: 767px) {
  #flex_add {
    text-align: left;
  }
}
@media screen and (max-width: 767px) {
  .media_page.build_order div#nav-tab {
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  a.total_pcs_btn,
a.check_out_btn {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .b_order_item {
    padding: 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .quantity input.minus,
.quantity input.plus,
.quantity input.input-text.qty.text_input {
    width: 40px;
  }
}
@media screen and (max-width: 767px) {
  .ab_about_title span {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .select_city,
.select_store {
    width: 100%;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  button.searchButton {
    width: 100%;
    margin: 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .pr_text p {
    font-size: 16px;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 767px) {
  .store_detail_sec {
    display: block;
    padding: 20px 0;
    margin-top: 35px;
  }
}
@media screen and (max-width: 767px) {
  ul.store_share li {
    margin-right: 6px;
  }
}
@media screen and (max-width: 767px) {
  .store_btn a.store_price_list,
.store_btn a.store_order_now {
    padding: 11px 25px;
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .store_pg_text {
    margin-top: 35px;
    padding: 0;
  }
}
@media screen and (max-width: 767px) {
  .left_sidebar.cate_sidebar,
.left_sidebar.arch_sidebar,
.left_sidebar.recent_post_sidebar {
    display: none;
  }
}
@media screen and (max-width: 767px) {
  .follow_block ul li {
    margin: 0 15px;
  }
}
@media screen and (max-width: 767px) {
  .page_bn_btn a.page_back_btn,
.page_bn_btn a.page_next_btn {
    margin: 0 7px;
  }
}
@media screen and (max-width: 767px) {
  .page_bn_btn a.page_back_btn {
    font-size: 15px;
    padding: 11px 35px;
    box-shadow: none;
    border: 1px solid #74b944;
  }
}
@media screen and (max-width: 767px) {
  .prog_step ul li {
    padding-right: 18% !important;
  }
}
@media screen and (max-width: 767px) {
  .add_new_form.active {
    width: 100%;
  }
}
@media screen and (max-width: 767px) {
  div.zabuto_calendar .table tr.calendar-dow-header th {
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  div.zabuto_calendar .table tr td div.day,
.slot_select ul li {
    font-size: 15px;
  }
}
@media screen and (max-width: 767px) {
  .slot_time {
    padding: 7px;
  }
}
@media screen and (max-width: 767px) {
  .slot_time ul li {
    font-size: 12px;
    margin: 15px 2px;
    padding: 15px 11px;
  }
}
@media screen and (max-width: 767px) {
  .pricing_main_section div#myTabContent {
    padding-right: 15px;
    padding-left: 15px;
    margin-top: 15px;
  }
}
@media screen and (max-width: 767px) {
  .top_tab_bar a {
    padding: 20px 4.9px;
    font-size: 10px !important;
    text-align: center;
  }
}
@media screen and (max-width: 767px) {
  .serv_mob_view.services_page_section {
    display: block;
    padding: 70px 0;
    margin: 0;
  }
}
@media screen and (max-width: 767px) {
  .services_page_section {
    display: none;
  }
}
@media only screen and (max-width: 480px) {
  .container {
    width: 90% !important;
    margin: 0 auto;
    max-width: 90%;
  }

  .address_pg_section {
    margin-top: -25px;
  }

  section.inner_banner_section.locate_banner,
section.inner_banner_section.our_value_banner,
section.inner_banner_section.policy_page_banner,
section.inner_banner_section.covid_page_banner,
section.inner_banner_section.blog_page_banner,
section.inner_banner_section.media_page_banner {
    min-height: 150px;
  }

  section.inner_banner_section.franchise_page_bannerv1,
section.inner_banner_section.pricing_page_banner,
section.inner_banner_section.date_time_page_banner,
section.inner_banner_section.address_page_banner,
section.inner_banner_section.order_summary_page_banner,
section.inner_banner_section.build_order_banner {
    min-height: 133px;
  }

  .footer_section {
    display: none !important;
  }
}
@media only screen and (max-width: 440px) {
  section.inner_banner_section.franchise_page_bannerv1,
section.inner_banner_section.pricing_page_banner,
section.inner_banner_section.date_time_page_banner,
section.inner_banner_section.address_page_banner,
section.inner_banner_section.order_summary_page_banner,
section.inner_banner_section.build_order_banner {
    min-height: 120px;
  }
}
@media only screen and (max-width: 400px) {
  section.inner_banner_section.franchise_page_bannerv1,
section.inner_banner_section.pricing_page_banner,
section.inner_banner_section.date_time_page_banner,
section.inner_banner_section.address_page_banner,
section.inner_banner_section.order_summary_page_banner,
section.inner_banner_section.build_order_banner {
    min-height: 110px;
  }
}
.top_tab_bar a {
  font-size: 14px;
  color: black !important;
  font-weight: 600;
  border-radius: 10px;
}

.md_form input[type=text]::placeholder {
  font-size: 13px;
}

.md_locate span {
  font-size: 13px;
}

.left_side_block {
  padding-right: 0;
}

.list_text p {
  font-size: 12px;
}

.locate_list li {
  display: inline-block;
  padding: 30px 10px;
}

.map_block {
  padding: 10px;
}

.locate_list li a {
  font-size: 14px;
  padding: 11px 17px;
}

.copy_right_text ul li {
  padding-left: 21px;
}

.copy_right_text ul li:nth-child(2):before {
  left: 6px;
}

.media_page nav a.nav-link {
  margin: 0px 27px 3px 35px;
}

.store_btn a.store_price_list,
.store_btn a.store_order_now {
  padding: 11px 20px;
  font-size: 15px;
}

.store_detail_sec ul li p {
  font-size: 14px;
}

ul.store_share p i {
  margin-left: 6px;
}

.store_map_sec iframe {
  height: 350px;
}

ul.store_share li {
  margin-right: 1px;
}

.slot_time {
  padding: 4px;
}

.slot_time ul li {
  font-size: 11px;
  margin: 15px 15px;
  padding: 15px 30px;
}

div.zabuto_calendar .table tr.calendar-dow-header th {
  font-size: 12px;
}

@media only screen and (max-width: 380px) {
  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light.bg-primary.text-white {
    background: green !important;
    width: 43px !important;
  }

  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day,
.date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light {
    width: 43px !important;
  }

  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-weekday.small {
    width: 100%;
  }

  section.inner_banner_section.locate_banner,
section.inner_banner_section.our_value_banner,
section.inner_banner_section.policy_page_banner,
section.inner_banner_section.covid_page_banner,
section.inner_banner_section.blog_page_banner,
section.inner_banner_section.media_page_banner {
    min-height: 125px;
  }
}
@media only screen and (max-width: 360px) {
  .container {
    width: 90% !important;
    margin: 0 auto;
    max-width: 90%;
  }

  .md_button button {
    font-size: 13px;
  }

  .md_locate i {
    margin-right: 3px;
    font-size: 13px;
  }

  .md_form i.fa.fa-caret-down {
    right: 0;
    color: #245a45;
    font-size: 16px;
    left: auto;
    top: 16px;
  }

  .tab_bar a#nav-home-tab:before {
    right: -22px;
    width: 1px;
    top: 14px;
    height: 23px;
  }

  .tab_bar a {
    padding: 13px 0;
    margin: 0 17px;
    font-size: 16px;
  }

  .left_side_block {
    padding-right: 0;
  }

  .list_text p {
    font-size: 12px;
  }

  div#locat_sec1 li:nth-child(even) {
    margin-left: 15px;
    width: 43%;
  }

  li#ft_sec4 .ft_link li:nth-child(3):before,
li#ft_sec4 .ft_link li:nth-child(2):before {
    top: 4px;
  }

  .locate_list li a {
    font-size: 14px;
    padding: 10px 13px;
  }

  .media_page nav a#nav-home-tab:before {
    right: -17px;
    width: 1px;
    top: 0;
    height: 24px;
  }

  .hp_banner_tilte h1,
.inner_banner_tilte h1 {
    font-size: 20px;
  }

  .prog_step ul li p {
    font-size: 12px;
  }

  .prog_step ul li {
    padding-right: 9%;
  }

  .prog_step ul li i {
    width: 30px;
    height: 30px;
    font-size: 12px;
  }

  .prog_step ul li:before {
    width: 93%;
  }

  .page_bn_btn a.page_back_btn,
.page_bn_btn a.page_next_btn {
    margin: 0px 3px;
  }

  section.inner_banner_section.franchise_page_bannerv1,
section.inner_banner_section.pricing_page_banner,
section.inner_banner_section.date_time_page_banner,
section.inner_banner_section.address_page_banner,
section.inner_banner_section.order_summary_page_banner,
section.inner_banner_section.build_order_banner {
    min-height: 96px;
  }
}
@media only screen and (max-width: 340px) {
  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day,
.date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light {
    width: 34px !important;
  }

  .date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light.bg-primary.text-white {
    background: green !important;
    width: 34px !important;
  }
}
@media only screen and (max-width: 375px) {
  input.form-check-input {
    position: absolute;
    right: 0px;
  }

  input.checkk {
    position: absolute;
    right: -127px !important;
  }
}
@media only screen and (max-width: 320px) {
  .container {
    width: 90% !important;
    margin: 0 auto;
    max-width: 90%;
  }

  section.inner_banner_section.locate_banner,
section.inner_banner_section.our_value_banner,
section.inner_banner_section.policy_page_banner,
section.inner_banner_section.covid_page_banner,
section.inner_banner_section.blog_page_banner,
section.inner_banner_section.media_page_banner {
    min-height: 100px;
  }

  section.inner_banner_section.franchise_page_bannerv1,
section.inner_banner_section.pricing_page_banner,
section.inner_banner_section.date_time_page_banner,
section.inner_banner_section.address_page_banner,
section.inner_banner_section.order_summary_page_banner,
section.inner_banner_section.build_order_banner {
    min-height: 90px;
  }
}
.order_summary_pg_section .order_summary_pg button.apply_coupon_btn {
  padding: 8px 10px;
  border-radius: 5px;
  border: 1px solid #d3d3d2;
  font-size: 14px;
  width: 47%;
  color: white;
  font-weight: bold;
  box-shadow: 2px 0px 29px rgba(0, 0, 0, 0.09);
  cursor: pointer;
  background-color: #7bbb43;
  position: relative;
  margin-left: 15px;
}

.coupon_text {
  width: 50%;
  display: inline-block;
}

.date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light.bg-primary.text-white {
  background: #7bbb43 !important;
  width: 5.3em;
}

.date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day,
.date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-day .btn-light {
  width: 5.3em;
}

.date_time_block ngb-datepicker#gfg .ngb-dp-content.ngb-dp-months .ngb-dp-month ngb-datepicker-month .ngb-dp-weekday.small {
  width: 100%;
  font-style: normal;
  color: white;
  font-size: 18px;
}

.date_time_block ngb-datepicker#gfg {
  border: none;
  box-shadow: 1px 0px 29px rgba(0, 0, 0, 0.12);
}

.date_time_block ngb-datepicker#gfg .ngb-dp-header,
.date_time_block ngb-datepicker#gfg .ngb-dp-week.ngb-dp-weekdays {
  background: #80ba4a;
}

.date_time_block ngb-datepicker#gfg .ngb-dp-header select.custom-select {
  background: #80ba4a;
  border: 0px;
  color: white;
  font-size: 20px;
}

section.career_team_section .uc_img_item .ng-image-slider .ng-image-slider-container .main .main-inner .img-div .youtube-icon {
  background: none !important;
}

.use-location-btn {
  bottom: 10px !important;
  z-index: 9999999 !important;
}

.btn-success {
  background-color: #7bbb43;
}

@media (max-width: 414px) {
  .order_info ul textarea#textareareview {
    width: 100%;
  }

  .ab_main_section {
    background-color: #eee;
  }

  .promise {
    padding: 10px;
  }

  .ab_promise_section {
    background-color: #eee;
  }

  .ab_pad {
    padding-top: 5%;
    padding-bottom: 3%;
  }

  .ab_mid_pad {
    padding: 3% 0;
  }

  .ab_la_pad {
    padding-top: 3%;
    padding-bottom: 5%;
  }

  .promise_text h4 {
    background: white;
    padding: 10px;
  }

  .promise_text p {
    background: white;
  }

  .pro-ser-p p {
    background-color: #eee;
  }

  .promise_block {
    padding: 20px 0;
    background: white;
    margin-top: 31px;
  }

  .pro-ser {
    background-color: #eee;
  }

  .ab_mid_sectopn {
    background-color: #eee;
  }

  .ab-wht {
    background-color: white;
    border-radius: 15px !important;
  }

  .choose {
    background-color: white !important;
  }

  .gun-back {
    background-color: #eee !important;
  }

  button.btn.btn-primary.place-order {
    margin: 11px 0 5px 0;
  }

  section {
    background-color: #eee;
  }

  .page_progress_section {
    padding: 9px 30px 34px !important;
  }
}
html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-option-text.mat-option-text {
  white-space: normal;
  line-height: 20px !important;
}

.mat-form-field {
  font-size: 15px;
}
.mat-form-field.mat-focused {
  font-size: 15px;
}
.mat-form-field .mat-input-element {
  width: 100%;
  font-size: 15px;
  color: #414141;
}
.mat-form-field::placeholder {
  font-size: 15px;
  color: #414141;
  font-weight: 500;
}
.mat-form-field.mat-focused .mat-form-field-wrapper .mat-form-field-label-wrapper .mat-form-field-label span {
  color: #414141;
}
.mat-form-field.mat-focused .mat-form-field-underline {
  border-color: #7bbb43;
}

.mat-form-field-should-float {
  font-size: 15px;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: #d2cecf;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: #414142;
}

.mat-form-field-disabled .mat-form-field-wrapper .mat-form-field-flex {
  background-color: #eee !important;
}

.mat-form-field-ripple {
  display: none;
}

.mat-focused {
  color: #414141 !important;
}

.header_section.mini-scroll {
  height: 50px;
}
.header_section.mini-scroll .location-disabled-strip {
  margin-top: 6px;
}
.header_section.mini-scroll .logo_sec img {
  height: 25px;
  margin-top: 8px;
}
.header_section.mini-scroll .location-icon {
  background: url(//assets.ucleanlaundry.com/assets/images/vector/location.svg) no-repeat 13px 15px;
}
.header_section.mini-scroll .location-form-control {
  font-size: 12px;
  height: 45px;
}
.header_section.mini-scroll .location-loader {
  font-size: 12px;
  margin-top: 5px;
}
.header_section.mini-scroll .menu_sec ul li {
  margin: 2px 16px;
}
.header_section.mini-scroll .menu_sec ul li a {
  font-size: 13px;
}
.header_section.mini-scroll .menu_sec ul li.active::after {
  height: 3px;
  bottom: -8.1px;
}
.header_section.mini-scroll .menu_sec ul li:nth-last-child(2) a {
  margin: 0px;
  padding: 0px;
  width: 127px;
}
.header_section.mini-scroll .menu_sec ul li:nth-last-child(1) a {
  margin: 0px;
  padding: 0px;
  width: 127px;
}
.header_section.mini-scroll.location-disabled::after {
  margin-top: 6px;
}

.main-content {
  padding-top: 72px;
}
.dflex, .screen .white-box-wrapper .white-box .form-wrapper .form-social-media-section .social-btn, .screen .white-box-wrapper, .screen .quote-wrapper .quote-content-box, .screen .quote-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.screen {
  min-height: 500px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-position: 100%;
}
.screen.signup {
  background-image: url(./assets/images/login/otp-verification.svg);
}
.screen.signup .quote-wrapper .quote-content-box {
  background: linear-gradient(210.28deg, rgba(255, 255, 255, 0.1) 18.4%, rgba(255, 255, 255, 0.15) 81.54%);
}
.screen.login-page {
  background-image: url(./assets/images/login/otp-verification.svg);
}
.screen.login-page .quote-wrapper .quote-content-box {
  background: linear-gradient(210.28deg, rgba(255, 255, 255, 0.1) 18.4%, rgba(255, 255, 255, 0.15) 81.54%);
}
.screen.otp-verification {
  background-image: url(./assets/images/login/otp-verification.svg);
}
.screen.otp-verification .quote-wrapper .quote-content-box {
  background: linear-gradient(90.06deg, rgba(0, 0, 0, 0.2) 0.1%, rgba(0, 0, 0, 0.4) 99.9%);
}
.screen.otp-success {
  background-image: url(./assets/images/login/otp-success.svg);
}
.screen.otp-success .quote-wrapper .quote-content-box {
  background: linear-gradient(90.06deg, rgba(0, 0, 0, 0.2) 0.1%, rgba(0, 0, 0, 0.4) 99.9%);
}
.screen .quote-wrapper .quote-content-box {
  width: 60%;
  min-height: 220px;
  backdrop-filter: blur(5px);
  border-radius: 30px;
  padding: 50px;
  margin-top: calc(50vh - 32vh);
  position: relative;
}
.screen .quote-wrapper .quote-content-box p {
  font-family: "Gilroy-SemiBold";
  font-size: 36px;
  line-height: 40px;
  color: rgba(255, 255, 255, 0.95);
}
.screen .quote-wrapper .quote-content-box p b {
  color: #003A26;
}
.screen .quote-wrapper .quote-content-box .quote-img {
  height: 56px;
  position: absolute;
  top: -25px;
  left: -10px;
}
.screen .white-box-wrapper .white-box {
  overflow: hidden;
  min-height: 70vh;
  width: 80%;
  background: #FFFFFF;
  box-shadow: 5px 5px 250px #657E96;
  border-radius: 50px;
  margin-top: 5vh;
  margin-bottom: 5vh;
  position: relative;
}
.screen .white-box-wrapper .white-box .form-wrapper {
  padding: 35px 45px;
}
.screen .white-box-wrapper .white-box .form-wrapper .backdrop {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.7);
}
.screen .white-box-wrapper .white-box .form-wrapper .backdrop img {
  margin-right: 5px;
  cursor: pointer;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-header-wrapper {
  display: flex;
  align-items: center;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-header-wrapper p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700 !important;
  font-size: 20px;
  line-height: 30px;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-header-wrapper.font-700 {
  font-size: 21px;
  line-height: 32px;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-header-wrapper .line {
  height: 1px;
  flex: auto;
  background-color: #65BA0D;
  margin-left: 10px;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-sub-header {
  margin-top: 15px;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-sub-header span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.7);
}
.screen .white-box-wrapper .white-box .form-wrapper .form-sub-header span.api-error {
  color: red;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-sub-header a {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #65BA0D;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-sub-header a b.black-b {
  color: black !important;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-sub-header.center-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-social-media-section .social-btn {
  background: #FFFFFF;
  border: 1.43049px solid rgba(0, 0, 0, 0.2);
  border-radius: 7.15243px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.7);
  height: 45px;
  margin-top: 15px;
  cursor: pointer;
}
.screen .white-box-wrapper .white-box .form-wrapper .form-social-media-section .social-btn img {
  margin-right: 15px;
}
.screen .white-box-wrapper .white-box .form-wrapper .section-partition {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.screen .white-box-wrapper .white-box .form-wrapper .section-partition .line {
  height: 1px;
  width: 70px;
  background-color: rgba(0, 0, 0, 0.5);
  margin: 0px 20px;
}
.screen .white-box-wrapper .white-box .form-wrapper .section-partition .partition-text {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}
.screen .white-box-wrapper .white-box .form-wrapper .full-width-input {
  height: 45px;
  width: 100%;
  background: #FFFFFF;
  border: 1.07286px solid rgba(0, 0, 0, 0.2);
  border-radius: 7.15243px;
  padding-left: 20px;
}
.screen .white-box-wrapper .white-box .form-wrapper .full-width-input.invalid {
  border: 1.07286px solid red;
  border-color: red;
}
.screen .white-box-wrapper .white-box .form-wrapper .full-width-input::placeholder {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: rgba(0, 0, 0, 0.4);
}
.screen .white-box-wrapper .white-box .form-wrapper .primary-btn {
  margin-top: 20px;
  width: 100%;
  background: #65BA0D;
  box-shadow: 0px 2.86097px 34.3317px rgba(0, 0, 0, 0.08);
  border-radius: 7.15243px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  outline: none;
  border: none;
  text-align: center;
  height: 47px;
  color: #FFFFFF;
}
.screen .white-box-wrapper .white-box .form-wrapper .primary-btn.disable {
  pointer-events: none;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.bounce-elm, .screen .white-box-wrapper .white-box .form-wrapper .full-width-input.invalid {
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-delay: 0.25s;
}

/* This approximates the ease-in-out-bounce animation from easings.net, which would require a plug-in to use*/
@keyframes bounce {
  0% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
  37% {
    transform: translateX(5px);
    timing-function: ease-out;
  }
  55% {
    transform: translateX(-5px);
    timing-function: ease-in;
  }
  73% {
    transform: translateX(4px);
    timing-function: ease-out;
  }
  82% {
    transform: translateX(-4px);
    timing-function: ease-in;
  }
  91% {
    transform: translateX(2px);
    timing-function: ease-out;
  }
  96% {
    transform: translateX(-2px);
    timing-function: ease-in;
  }
  100% {
    transform: translateX(0px);
    timing-function: ease-in;
  }
}
.blur {
  -webkit-filter: blur(1px);
  -moz-filter: blur(1px);
  -o-filter: blur(1px);
  -ms-filter: blur(1px);
  filter: blur(1px);
  pointer-events: none;
}

.pac-container {
  z-index: 9999;
}